import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Button, Grid} from '@material-ui/core';
import {SearchInput} from '../../../../Components';

const useStyles = makeStyles(theme => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    searchInput: {
        marginRight: theme.spacing(1)
    }
}));

const ApisToolbar = ({ handleDialogOpen ,handleSearchChange , ...rest  }) => {
    const classes = useStyles();
    const handleAdd = () => {handleDialogOpen()}
    return (
        <div
            {...rest}
            className={classes.root}
        >
            <Grid
                container
                spacing={3}
            >
                <Grid item xs={6}>
                    <div className={classes.row}>
                        <SearchInput
                            className={classes.searchInput}
                            placeholder="Search Api "
                            onChange={handleSearchChange}
                        />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.row}>
                        <span className={classes.spacer}/>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleAdd}
                        >
                            Add Api
                        </Button>
                    </div>
                </Grid>

            </Grid>
        </div>
    );
};

export default ApisToolbar;
