import React from 'react';
import PropTypes from 'prop-types';
import {Typography, Link} from '@material-ui/core';

const Footer = props => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.beemetrix.com">
                BeeMetrix
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
};

Footer.propTypes = {
    className: PropTypes.string
};

export default Footer;
