import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Grid,
    Paper,
    Typography,
    Button, Dialog,
} from '@material-ui/core';
import {ConnectorForm} from "../../../ConnectorsList/components";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        borderRadius: 8,
    },
    connectorPaper: {
        padding: theme.spacing(2),
        margin: theme.spacing(2, 0),
        border: '1px solid #ccc',
        borderRadius: 8,
        backgroundColor: '#f9f9f9',
        textAlign: 'center',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    },
    title: {
        marginBottom: theme.spacing(2),
        color: theme.palette.text.primary,
    },
}));

const Connectors = ({ values, setValues, handleAlert, setLoading, loading }) => {
    const classes = useStyles();
    const [dialogState, setDialogState] = useState({ open: false, type: '' });

    const handleAddConnector = (type) => {
        setDialogState({ open: true, type });
    };

    const handleCloseDialog = () => {
        setDialogState({ open: false, type: '' });
    };

    return (
        <Grid justifyContent='center' container spacing={2}>
            <Grid item xs={12}>
                <Typography align={"center"} variant={'h6'}>
                    Add Connectors
                </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                <Paper className={classes.connectorPaper} onClick={() => handleAddConnector('source')}>
                    {
                        values.connectors.source ?
                            <Typography variant="subtitle1">Sink Connector : {values.connectors.source.name} </Typography>
                            :
                            <Typography variant="subtitle1">Add Source Connector</Typography>
                    }
                </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
                <Paper className={classes.connectorPaper} onClick={() => handleAddConnector('sink')}>
                    {
                        values.connectors.sink ?
                            <Typography variant="subtitle1">Sink Connector : {values.connectors.sink.name} </Typography>
                                :
                            <Typography variant="subtitle1">Add Sink Connector</Typography>
                    }
                </Paper>
            </Grid>

            <Dialog open={dialogState.open} onClose={handleCloseDialog}>
                <ConnectorForm
                    open={dialogState.open}
                    handleCloseForm={handleCloseDialog}
                    handleAlert={handleAlert}
                    connectorType={dialogState.type}
                    connectorTopic={values.topic?.id}
                    connectorThing = {values.thing.thing_id}
                    connectorPermission={values.thing.permission.topic}
                    setValues={setValues}
                />
            </Dialog>
        </Grid>
    );
};

Connectors.propTypes = {
    values: PropTypes.object.isRequired,
    setValues: PropTypes.func.isRequired,
    handleAlert: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
};

export default Connectors;
