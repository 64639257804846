import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Grid, IconButton, Typography} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {ApiCard, ApiForm, ApisToolbar} from './components';
import Divider from "@material-ui/core/Divider";
import {fetchApis, withErrorHandlingAndLoading} from "../../constants/functions";
import Loading from "../../Components/Loading/Loading";
import MessageAlert from "../../Components/Alert";
import {useStateValue} from "../../StateContext";
import {actionTypes} from "../../StateContext/types";
import {ApiDialog} from "./ApiDialog";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    },
    pagination: {
        marginTop: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    }
}));


const ApiList = props => {

    const classes = useStyles();
    const {apisState, apisDispatch} = useStateValue([]);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({
        open: false,
        message: '',
        severity: 'info'
    });
    const handleAlert = useCallback((open, message, severity) => {
        setAlert({
            open,
            message,
            severity
        });
    }, []);
    const handleClose = useCallback(() => setAlert(prevState => ({ ...prevState, open: false })), []);

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const fetchApiList = withErrorHandlingAndLoading(async () => {
        const apis = await fetchApis();
        apisDispatch({
            type : actionTypes.SET_APIS,
            value : apis
        })
    },setLoading , handleAlert);

    useEffect(() => {fetchApiList()}, []);

    const [searchQuery, setSearchQuery] = useState('');
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
    const filteredData = apisState.filter((item) =>
        item.modelName.toLowerCase().includes(searchQuery.toLowerCase())
    );


    return (
        <div className={classes.root}>

            <MessageAlert
                open={alert.open}
                message={alert.message}
                onClose={handleClose}
                severity={alert.severity}
            />

            <ApisToolbar
                handleDialogOpen={handleDialogOpen}
                handleSearchChange={handleSearchChange}
            />

            <div className={classes.content}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    {loading ?
                        <Grid container justifyContent={"center"} alignItems={"center"}>
                            <Loading/>
                        </Grid>
                        :
                        filteredData.map(item => (
                            <Grid item key={item.id} xs={3}>
                                <ApiCard product={item} handleAlert={handleAlert}/>
                            </Grid>
                        ))}
                </Grid>
            </div>
            <div className={classes.pagination}>
                <IconButton>
                    <ChevronLeftIcon/>
                </IconButton>
                <Typography variant="caption">{loading ? 0 : apisState.length}</Typography>
                <IconButton>
                    <ChevronRightIcon/>
                </IconButton>
            </div>

            <ApiDialog {...{dialogOpen, handleDialogClose, handleAlert}}/>

        </div>
    );
};

export default ApiList;
