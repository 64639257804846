import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {IconButton, Grid, Typography, Divider, Dialog, DialogTitle, DialogContent} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { ConnectorCard , ConnectorForm, ConfigForm } from './components';
import { withErrorHandlingAndLoading} from '../../constants/functions';
import { useStateValue } from '../../StateContext';
import { actionTypes } from '../../StateContext/types';
import Loading from "../../Components/Loading/Loading";
import MessageAlert from "../../Components/Alert";
import {MessageCard} from "../../Components/MessageCard";
import { ActionsToolbar } from "../../Components";
import {fetchAllConnectors} from "../../constants/connectors";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
    },
    pagination: {
        marginTop: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
}));

const ConnectorsList = ({ history }) => {

    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);

    const [openForm, setOpenForm] = useState('');

    const handleOpenForm = (type) => {
        setOpenForm(type)
    };

    const handleCloseForm = () => {setOpenForm('')};

    const [alert, setAlert] = useState({
        open: false, message: "", severity: "info"
    });

    const handleAlert = (open, message, severity) => {
        setAlert({ open, message, severity });
    };
    const handleAlertClose = () => {
        setAlert(prevState => ({ ...prevState, open: false }));
    };

    const { connectorsState, connectorsDispatch, connectorInfoState } = useStateValue();

    useEffect(() => {
        const fetchData = withErrorHandlingAndLoading(async () => {
            const data = await fetchAllConnectors();
            connectorsDispatch({ type: actionTypes.SET_CONNECTORS, value: data });
        },setLoading,handleAlert);

        fetchData();
    }, []);


    const [searchQuery, setSearchQuery] = useState('');
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
    const filteredData = connectorsState.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>

            <MessageAlert
                open={alert.open}
                message={alert.message}
                onClose={handleAlertClose}
                severity={alert.severity}
            />

            <ActionsToolbar
                title={'Connector'}
                handleSearch = {handleSearchChange}
                openForm={handleOpenForm}
            />

            { loading ? <Loading/> :
                    <Grid container spacing={3}>
                        <Grid item xs={12}><Divider /> </Grid>
                        {filteredData.map(Connector => (
                            <Grid  item key={Connector.id} xs={12} sm={6} md={4} lg={3}>
                                <ConnectorCard
                                    history={history}
                                    name = {Connector.name}
                                    id = {Connector.id}
                                    status = {Connector.status}
                                    config = {Connector.config}
                                    handleAlert={handleAlert}
                                    openForm={ handleOpenForm }
                                />
                            </Grid>
                        ))}
                    </Grid>
            }

            <div className={classes.pagination}>
                <IconButton>
                    <ChevronLeftIcon />
                </IconButton>
                <Typography variant="caption">{filteredData.length}</Typography>
                <IconButton>
                    <ChevronRightIcon />
                </IconButton>
            </div>

            <Dialog open={Boolean(openForm)} onClose={handleCloseForm}>
                {   openForm === 'ADD' ?
                        <ConnectorForm
                            handleAlert={handleAlert}
                            handleCloseForm={handleCloseForm}
                        /> :
                    openForm === 'INFO' ? <>
                                <DialogTitle disableTypography={false}>{connectorInfoState.name}</DialogTitle>
                                <DialogContent dividers>
                                    <ConfigForm initialValues={connectorInfoState}/>
                                </DialogContent>
                            </>
                            : null
                }
            </Dialog>

        </>
    );
};

export default ConnectorsList;
