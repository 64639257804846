import React from 'react';
import { TextField, Grid, Typography, makeStyles } from "@material-ui/core";
import { useStateValue } from "../../../../../StateContext";
import {actionTypes} from "../../../../../StateContext/types";

const useStyles = makeStyles((theme) => ({
    formContainer: {
        padding: theme.spacing(2),
        backgroundColor: "#f9f9f9",
        borderRadius: theme.spacing(1),
    },
}));

const MetadataForm = () => {
    const classes = useStyles();
    const {
        metadataState,
        metadataDispatch
    } = useStateValue();

    const handleInputChange = (e) => {
        metadataDispatch({
            type: actionTypes.EDIT_METADATA_VALUES ,
            key: e.target.name,
            value: e.target.value
        });
    };

    return (
        <Grid container spacing={2} className={classes.formContainer}>
            <Grid item xs={12}>
                <Typography variant="h6">Product : {metadataState.productName}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    label="Devices"
                    name="devices"
                    variant="outlined"
                    type="number"
                    required
                    fullWidth
                    value={metadataState.devices}
                    onChange={handleInputChange}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    label="Messages"
                    name="messages"
                    variant="outlined"
                    type="number"
                    required
                    fullWidth
                    value={metadataState.messages}
                    onChange={handleInputChange}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    label="Level"
                    name="level"
                    variant="outlined"
                    type="number"
                    required
                    fullWidth
                    value={metadataState.level}
                    onChange={handleInputChange}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    label="Price"
                    name="price"
                    variant="outlined"
                    type="number"
                    required
                    fullWidth
                    value={metadataState.price}
                    onChange={handleInputChange}
                />
            </Grid>
        </Grid>
    );
};

export default MetadataForm;
