import React, {useState, useEffect, useCallback} from "react";
import { Container, Paper, Box } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import CustomizedSteppers from "./Stepper";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import {getUserFromLocalStorage, getQueryParam, withErrorHandlingAndLoading} from '../../../../constants/functions';
import MethodsForm from './Forms/MethodsForm';
import {createSubscription, publishableKeyGet} from "../../../../constants/stripe";
import MessageAlert from "../../../../Components/Alert";
import Loading from "../../../../Components/Loading/Loading";

const useStyles = makeStyles(theme => ({
}));

const Main = () => {
    
    const classes = useStyles();
    const [stripePromise, setStripePromise] = useState(null);
    const [showStepper, setShowStepper] = useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [planInfo, setPlanInfo] = useState({ planName: '', priceId: '' });

    const [alert, setAlert] = useState({open: false, message: '', severity: 'info'});
    const handleAlert = useCallback((open, message, severity) => {
        setAlert({open, message, severity});
    }, []);
    const handleClose = useCallback(() => setAlert(prevState => ({ ...prevState, open: false })), []);

    useEffect(() => {

        const fetchUserData = async () => {
            const user = getUserFromLocalStorage();
            if (user && user.CusId) {
                setUser(user);
                setPlanInfo({
                    planName: getQueryParam('planName'),
                    priceId: getQueryParam('priceId')
                });
            }
        };

        const retrievePublishableKey = withErrorHandlingAndLoading(async () => {
            const publishableKey = await publishableKeyGet();
            const stripe = loadStripe(publishableKey);
            setStripePromise(stripe);
        },setLoading , handleAlert);

        retrievePublishableKey();
        fetchUserData();

    }, [user?.CusId, planInfo.planName, planInfo.priceId]);

    const openStepper = () => {
        setShowStepper(true);
    };

    const closeStepper = () => {
        setShowStepper(false);
    };

    return (
        <Box component="main" className={classes.boxWrapper}>

                <MessageAlert
                    open={alert.open}
                    message={alert.message}
                    onClose={handleClose}
                    severity={alert.severity}
                />

                {loading && <Loading/> }

                <Paper elevation={5}>
                    {stripePromise && (
                        <Elements stripe={stripePromise}>
                            {showStepper ? (
                                <CustomizedSteppers handleAlert={handleAlert} customerId={user?.CusId} closeStepper={closeStepper} />
                            ) : (
                                <MethodsForm user={user} onAdd={openStepper} priceId={planInfo.priceId}/>
                            )}
                        </Elements>
                    )}
                </Paper>

        </Box>
    );
};

export default Main;
