import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid } from '@material-ui/core';
import SearchInput from "../../Components/SearchInput";

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(2),
    },
    searchInput: {
        marginRight: theme.spacing(1),
        width: '100%',
        maxWidth: 300,
    },
    addButton: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1),
            width: '100%',
        },
    },
}));

const Toolbar = ({ handleSearch , openForm }) => {
    const classes = useStyles();

    const handleAdd = () => {  openForm() };

    return (
        <div className={ classes.root }>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <SearchInput
                        className={classes.searchInput}
                        placeholder="Search database"
                        onChange={handleSearch}
                    />
                </Grid>
                <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                    <Button
                        className={classes.addButton}
                        color="primary"
                        variant="contained"
                        onClick={handleAdd}
                    >
                        Add Database
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default Toolbar;
