import {Divider, Grid, Typography} from "@material-ui/core";
import React from "react";

export const CredentialsTab = ({selectData}) => (
    <Grid container spacing={3} style={{margin: '10px'}}>
        <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary" component="p">
                Host
            </Typography>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="h6" color="textSecondary" component="h4">
                {process.env.REACT_APP_DOMAIN}
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Divider/>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary" component="p">
                Username
            </Typography>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="h6" color="textSecondary" component="h4">
                {selectData.username}
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Divider/>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary" component="p">
                Password
            </Typography>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="h6" color="textSecondary" component="h4">
                {selectData.password}
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Divider/>
        </Grid>
    </Grid>
);