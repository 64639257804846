import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Snackbar from "@material-ui/core/Snackbar";

function MessageAlert({ open , message, severity, onClose }) {
    let backgroundColor, textColor;

    switch (severity) {
        case 'success':
            backgroundColor = 'green';
            textColor = 'white';
            break;
        case 'error':
            backgroundColor = 'red';
            textColor = 'white';
            break;
        case 'info':
            backgroundColor = 'blue';
            textColor = 'white';
            break;
        default:
            backgroundColor = 'grey';
            textColor = 'black';
    }

    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
            <Alert
                severity={severity}
                onClose={onClose}
                style={{
                    backgroundColor,
                    color: textColor
                }}
            >
                {message}
            </Alert>
        </Snackbar>
    );
}

export default MessageAlert;
