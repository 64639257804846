import axios from "axios";
import {base_url, errorHandler} from "./functions";

const auth_url = base_url + '/auth';

export const login = async (credentials) => {
    try {
        const response = await axios.post(`${auth_url}/login`, credentials);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}