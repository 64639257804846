import React, { useState } from 'react';
import { Card, Button, CardHeader, CardActions, Switch, CardContent, Typography, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@material-ui/core';
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons'; // Import Delete and Edit icons
import { useHistory } from 'react-router-dom';
import { isAdmin} from '../../constants/functions';
import { useStateValue } from "../../StateContext";
import { actionTypes } from "../../StateContext/types";

import { withStyles } from '@material-ui/core/styles';
import {archiveProduct, changePlan, createSubscription} from "../../constants/stripe";


const StyledSwitch = withStyles({
    switchBase: {
        color: 'primary',
        '&$checked': {
            color: 'secondary',
        },
        '&$checked + $track': {
            backgroundColor: 'secondary',
        },
    },
    checked: {},
    track: {},
})(Switch);

const Plan = (props) => {
    const [loading, setLoading] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false); // State to control confirmation dialog
    const customerId = props.customerId;
    const current = props.priceid && props.currentPlan.priceId === props.priceid;
    const nextPlan = props.currentPlan.nextPriceId === props.priceid;
    const currentLevel = props.currentPlan.level;
    const ongoing = props.currentPlan.ongoing;
    const endDate = (current || nextPlan) ? (new Date(props.currentPlan.currentend * 1000)).toDateString() : null;
    const price = props.price;
    const features = props.features;
    const history = useHistory();

    const { productsDispatch, } = useStateValue();

    const handleSubscribe = () => {
        history.push(`/plan/payments?planName=${props.name}&priceId=${props.priceid}`);
    };

    const handleSchedule = async () => {
        setLoading(true);
        const resp = await createSubscription(customerId, props.priceid);
        props.setCurrentPlan(resp);
        await props.fetchCustomerInfoData();
        setLoading(false);
    };

    const changeContinuation = async () => {
        setLoading(true);
        await changePlan(props.customerId);
        await props.fetchCustomerInfoData();
        setLoading(false);
    };

    const formatAmount = (amount) => {
        if (amount > 1000000) {
            return (amount / 1000000) + " M";
        } else if (amount > 1000) {
            return (amount / 1000) + " K";
        } else {
            return amount;
        }
    };

    const handleUpdate = async () => {
        await props.handleMetadaDataFormOpen({
            productId: props.productId,
            price: props.price,
            productName: props.name,
            ...props.features
        });
    };

    const handleArchive = async (e) => {
        try {
            const checked = e.target.checked
            await archiveProduct(props.productId, checked);
            productsDispatch({
                type: actionTypes.ARCHIVE_PRODUCT,
                productId: props.productId,  // Assuming resp.id is the ID of the product
                active: checked
            });
            // Handle any UI updates or notifications upon successful deletion
        } catch (error) {
            console.error('Error deleting product:', error);
            // Handle error response or notifications
        }
    };

    return (
        <Card variant="outlined" style={{
            height: '100%',
            minHeight: '500px',
            display: 'flex',
            flexDirection: 'column',
            boxShadow: '8px 8px 8px rgba(0, 0, 0, 0.1)',
            backgroundColor: current ? 'rgba(0, 0, 255, 0.1)' : 'transparent',
            border: nextPlan ? "2px solid blue" : null
        }}>
            <CardHeader
                style={{ marginBottom: "30px" }}
                title={<Typography variant='h5' align='center'> {props.name} </Typography>}
            />

            <CardHeader
                style={{
                    height: '100%',
                    minHeight: '100px',
                }}
                title={
                    price === 0 ?
                        <Typography variant='h4' align='center'> Free </Typography> :
                        price === 5 ?
                            <>
                                <Typography variant='h4' align='center'> Custom Price </Typography>
                                <Typography variant='subtitle2' align='center'>Per Unit/Month</Typography>
                            </> :
                            <>
                                <Typography variant='h4' align='center'>${price}</Typography>
                                <Typography variant='subtitle2' align='center'>Per Unit/Month</Typography>
                            </>
                }
            />
            <CardContent>
                <ul>
                    <hr />
                    {Object.entries(features).map(([key, value]) => {
                        const amount = current ? value * props.currentPlan.quantity : value;
                        return (key === "level" || <li key={key}>{formatAmount(amount) + " " + key}</li>)
                    })}
                </ul>
                {!ongoing &&
                <Typography variant='h6' style={{ color: "Red" }}>
                    {current && <> Ends {endDate}</> || nextPlan && <> Starting {endDate}</>}
                </Typography>
                }
            </CardContent>

            <div style={{ flexGrow: 1 }}></div>

            <CardActions style={{justifyContent:"flex-end"}} >
                {isAdmin() ? (
                    <>

                        <div>
                            {/* State indicator */}
                            {props.isActive ? (
                                <span style={{ color: 'green', marginRight: '8px' }}>Active</span>
                            ) : (
                                <span style={{ color: 'red', marginRight: '8px' }}>Archived</span>
                            )}
                        </div>

                    <div>
                        {/* Toggle switch for activation/archival */}
                        {isAdmin() && (
                            <StyledSwitch
                                checked={props.isActive}
                                onChange={handleArchive}
                                color="primary"
                            />
                        )}
                        {/* Edit button - Show only if isActive is true */}
                        {props.isActive && isAdmin() && (
                            <IconButton color="primary" onClick={handleUpdate}>
                                <EditIcon />
                            </IconButton>
                        )}
                    </div>
                    </>
                ) : (
                    current ? (
                        <>
                            <Button variant="contained" color="primary" onClick={handleSubscribe}>
                                {props.currentPlan.quantity + " Units | +"}
                            </Button>

                            {props.price > 0 && (
                                <Button variant="contained" color="primary" disabled={loading} onClick={changeContinuation}>
                                    {loading ? 'Processing...' : ongoing ? 'Cancel' : 'Reactivate'}
                                </Button>
                            )}
                        </>
                    ) : (
                        <>
                            {nextPlan || (
                                <Button variant="contained" color="primary" disabled={loading} onClick={() => {
                                    if (props.features.level <= currentLevel) {
                                        handleSchedule();
                                    } else {
                                        handleSubscribe();
                                    }
                                }}>
                                    {loading ? "loading..." : props.features.level <= currentLevel ? 'Schedule' : props.currentPlan.priceId ? 'Upgrade' : 'Subscribe'}
                                </Button>
                            )}
                        </>
                    )
                )}
            </CardActions>

            {/* Confirmation dialog
            <Dialog open={openConfirmation} onClose={() => setOpenConfirmation(false)}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you want to delete this product?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmation(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleArchive} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        */}
        </Card>
    );
};
export default Plan;
