import React, { useEffect, useState } from 'react';
import {
    makeStyles, Grid, Card, CardContent, CardMedia, Avatar, CardHeader,
    CardActions, IconButton, Link, Dialog, DialogTitle, DialogContent,
    DialogActions, Typography, Divider, Slide, Button
} from '@material-ui/core';
import {GetApp as GetAppIcon} from '@material-ui/icons';
import ReactJson from 'react-json-view';
import {deleteLib, downloadLib, listLibs, withErrorHandlingAndLoading} from '../../constants/functions';
import MessageAlert from '../../Components/Alert';
import {Loading} from "../../Components";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    content: {
        alignItems: 'center',
        display: 'flex'
    },
    title: {
        fontWeight: 700
    },
    avatar: {
        backgroundColor: theme.palette.error.main,
        height: 56,
        width: 56
    },
    icon: {
        height: 32,
        width: 32
    },
    difference: {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center'
    },
    differenceIcon: {
        color: theme.palette.error.dark
    },
    differenceValue: {
        color: theme.palette.error.dark,
        marginRight: theme.spacing(1)
    },
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    searchInput: {
        marginRight: theme.spacing(1)
    },
    media: {
        height: 150,
        paddingTop: '40.25%'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: 'rotate(180deg)'
    }
}));


const Biblio = ({ history, url, className, ...rest }) => {
    const classes = useStyles();

    const [schemas, setSchemas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ open: false, message: "", severity: "info" });
    const [open, setOpen] = useState(false);
    const [dt, setDt] = useState({});
    const [name, setName] = useState("");
    const [openDelete, setOpenDelete] = useState(false);

    const handleAlert = (open, message, severity) => {
        setAlert({ open, message, severity });
    };

    const handleAlertClose = () => {
        setAlert(prevState => ({ ...prevState, open: false }));
    };

    const getLibs = withErrorHandlingAndLoading(async () => {
        const response = await listLibs();
        setSchemas(response);
    }, setLoading, handleAlert);

    useEffect(() => {
        getLibs();
    }, []);

    const handleDeleteContinue = async () => {
        try {
            await deleteLib(name)
            await getLibs();
            setOpenDelete(false);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleClickOpen = async (data) => {
        await downloadLib(data);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <MessageAlert
                open={alert.open}
                message={alert.message}
                onClose={handleAlertClose}
                severity={alert.severity}
            />
            {loading && <Loading/>}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <CardHeader title="Downloads" />
                </Grid>
                <Grid item xs={12}><Divider /></Grid>
                <Grid container spacing={3}>
                    {schemas.map(product => (
                        <Grid item lg={3} sm={6} xl={2} xs={12} key={product.label}>
                            <Card className={classes.root}>
                                <CardHeader
                                    avatar={<Avatar aria-label="recipe" className={classes.avatar}>SDK</Avatar>}
                                    title={product.label}
                                    subheader={new Date(Date.now()).toDateString()}
                                />
                                <CardMedia
                                    className={classes.media}
                                    image={`${url}/${product.link}`}
                                    title={product.info}
                                />
                                <CardContent>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {product.info}
                                    </Typography>
                                    {['espressif', 'gsm', 'arduino'].map(key => (
                                        product.get === key && (
                                            <Typography key={key} className={classes.root}>
                                                <Link href="#" onClick={() => handleClickOpen(product.get)}>
                                                    {key.toUpperCase()}
                                                </Link>
                                                <IconButton onClick={() => handleClickOpen(product.get)}>
                                                    <GetAppIcon />
                                                </IconButton>
                                            </Typography>
                                        )
                                    ))}
                                </CardContent>
                                <CardActions disableSpacing />
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
                    <DialogTitle>Confirmation</DialogTitle>
                    <DialogContent dividers>
                        Are you sure you want to delete this template?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteContinue} color="primary" size="small">Yes</Button>
                        <Button onClick={() => setOpenDelete(false)} color="primary" size="small">No</Button>
                    </DialogActions>
                </Dialog>
                <Dialog onClose={handleClose} open={open}>
                    <DialogTitle>Description</DialogTitle>
                    <DialogContent dividers>
                        <ReactJson src={dt} />
                    </DialogContent>
                </Dialog>
            </Grid>
        </div>
    );
};

export default Biblio;
