import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { Card, CardHeader, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    root: {
        height: '100%',
    },
}));

const BarChart = ({ data}) => {
    const classes = useStyles();

    const chartData = {
        labels: data.map(item => item.label),
        datasets: [
            {
                label: 'Messages Count',
                data: data.map(item => item.value),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
                barPercentage: 0.05,
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <Card className={classes.root} >
            <CardHeader
                title="Messages Count per Topic"
            />
            <div>
                <Bar data={chartData} options={options} />
            </div>
        </Card>
    );
};

BarChart.propTypes = {
    className: PropTypes.string,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
        })
    ).isRequired,
};

export default BarChart;
