import React, { useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import {
    Card,
    CardHeader,
    CardContent,
    Grid,
    Divider,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    IconButton,
    Collapse,
    FormControlLabel,
    Checkbox,
    Box, MenuItem
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    AddAclRecord,
    fetchAclRecords, fetchDatabases,
    fetchKafkaTopics,
    fetchThings, withErrorHandlingAndLoading
} from '../../../../constants/functions';
import { actionTypes } from '../../../../StateContext/types';
import { useStateValue } from '../../../../StateContext';
import Loading from '../../../../Components/Loading/Loading';
import FileUpload from './FileUpload';
import AddForm from "../../../DbaseManager/AddForm";
import {DialogHandler} from "../../../../Components";
import {addConnector} from "../../../../constants/connectors";

const useStyles = makeStyles((theme) => ({
    item: {
        display: 'flex',
        flexDirection: 'column'
    },
    button: {
        margin: theme.spacing(1),
    }
}));

const ConnectorForm = ({ handleAlert, handleCloseForm, connectorType, connectorTopic, connectorThing, connectorPermission , setValues}) => {

    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [acls, setAcls] = useState([]);
    const [openAddDb, setOpenAddDb] = useState(false);

    const [classOptions, setClassOptions] = useState(
        connectorType === "source" ?
            [{ value: "com.thingwings.MqttSourceConnector", label: "MQTT Connector" }]
                :
            [
                { value: "io.confluent.connect.elasticsearch.ElasticsearchSinkConnector", label: "Elasticsearch Connector" },
                { value: "io.confluent.connect.jdbc.JdbcSinkConnector", label: "PostgreSQL Connector" },
            ]);
    const [converterOptions, setConverterOptions] = useState([]);
    const [useCustomConfig, setUseCustomConfig] = useState(false);
    const [useMqttSSL, setMqttSSL] = useState(false);
    const [addingPermission, setAddingPermission] = useState(false);
    const [newPermission, setNewPermission] = useState("");
    const [selectedThing, setSelectedThing] = useState(null);
    const {
        kafkaTopicsState, kafkaTopicsDispatch,
         connectorsDispatch, thingsDispatch,
        thingsState, databasesDispatch, databasesState
    } = useStateValue();

    const formik = useFormik({
        initialValues: {
            name:  '',
            type: connectorType || '',
            topic: connectorTopic || '',
            connectorClass: '',
            valueConverter: '',
            thing: connectorThing || '',
            acl: connectorPermission || '',
            username: '',
            password: '',
            connectionUrl: '',
            sourceTopic: ''
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required('Name is required'),
            type: Yup.string().required('Connector Type is required'),
            topic: Yup.string().required('Topic is required'),
            connectorClass: Yup.string().required('Connector Class is required'),
            valueConverter: Yup.string().required('Value Converter is required'),
        }),
        onSubmit: withErrorHandlingAndLoading(async (values) => {
            const response = await addConnector({ useCustomConfig, useMqttSSL, ...values });
            connectorsDispatch({
                type: actionTypes.ADD_CONNECTOR,
                connector: response.concatenatedData
            });
           if(setValues) {
               if(connectorType === 'sink')
                   setValues((prevValues) => ({
                       ...prevValues,
                       connectors: {...prevValues.connectors, sink: response.concatenatedData },
                   }))
               else if( connectorType === 'source')
                   setValues((prevValues) => ({
                       ...prevValues,
                       connectors: {...prevValues.connectors , source: response.concatenatedData },
                   }))
           }
            handleCloseForm();
            handleAlert(true, "Connector added successfully", 'success');
        }, setLoading, handleAlert)
    });

    useEffect(() => {
        const fetchData = withErrorHandlingAndLoading(async () => {
            const topics = await fetchKafkaTopics();
            kafkaTopicsDispatch({ type: actionTypes.SET_TOPICS, value: topics.topics })
        }, setLoading, handleAlert);

        fetchData();
    }, []);

    useEffect(() => {
        const fetchThingsData = withErrorHandlingAndLoading(async () => {
            if (formik.values.type === 'source') {
                const things = await fetchThings();
                thingsDispatch({ type: actionTypes.SET_THINGS, value: things });
            }
        },setLoading,handleAlert);

        fetchThingsData();

    }, [formik.values.type]);

    useEffect(() => {
        if (formik.values.thing) {
            const getAclRecords = withErrorHandlingAndLoading(async () => {
                const response = await fetchAclRecords(formik.values.thing);
                setAcls(response);
            }, setLoading, handleAlert);
            getAclRecords();
        }
    }, [formik.values.thing]);

    useEffect(() => {
        if ( useCustomConfig && formik.values.type === 'sink') {
            const getDatabases = withErrorHandlingAndLoading(async () => {
                const response = await fetchDatabases();
                databasesDispatch({ type: actionTypes.SET_DATABASES, value: response })
            }, setLoading, handleAlert);

            getDatabases();
        }
    }, [useCustomConfig,formik.values.type]);

    const handleTypeChange = async (event) => {
        const newValue = event.target.value;
        await formik.setFieldValue('type', newValue);
        if (newValue === "source") {
            setClassOptions([
                { value: "com.thingwings.MqttSourceConnector", label: "MQTT Connector" },
                //{ value: "com.test.HttpSourceConnector", label: "HTTP Connector" }
            ]);
        } else {
            setClassOptions([
                { value: "io.confluent.connect.elasticsearch.ElasticsearchSinkConnector", label: "Elasticsearch Connector" },
                { value: "io.confluent.connect.jdbc.JdbcSinkConnector", label: "PostgreSQL Connector" },
            ]);
        }
    };

    const handleClassChange = (event) => {
        const newValue = event.target.value;
        formik.setFieldValue('connectorClass', newValue);

        setConverterOptions(newValue === "com.thingwings.MqttSourceConnector"
            ? [{ value: "org.apache.kafka.connect.converters.ByteArrayConverter", label: "Byte Array Converter" }]
            : [{ value: "org.apache.kafka.connect.json.JsonConverter", label: "JSON Converter" }]);
    };

    const handleThingChange = (event) => {
        const thingId = event.target.value;
        formik.setFieldValue('thing', thingId);
        setSelectedThing(thingsState.find(thing => thing.thing_id === Number(thingId)));
    };

    const handleCheckboxChange = (event) => {
        setUseCustomConfig(event.target.checked);
    };

    const handleDbChange = (event) => {
        const { value } = event.target;
        if (value === 'add.db') {
            // Call function to open the dialog
            setOpenAddDb(true);
            formik.setFieldValue('dbId', null);
        } else {
            // Use Formik's handleChange for other selections
            formik.handleChange(event);
        }
    };

    const handleMqttSSL = (event) => {
        setMqttSSL(event.target.checked);
    };

    const handleAddPermission = withErrorHandlingAndLoading(async () => {
        await AddAclRecord({
            aclUserId: selectedThing.id,
            thingId: selectedThing.thing_id,
            deviceId: selectedThing.device_id,
            topicPath: newPermission,
            pubsub: 3
        });

        setAcls(prevAcls => [...prevAcls, { acl_id: selectedThing.id, topic: `${selectedThing.device_id}/${newPermission}`, pubsub: 3 }]);

        handleAlert(true, "Permission added successfully", 'success');
    }, setLoading, handleAlert);

    return (
        <>
            <Card className={classes.root}>
                <CardHeader title="Connector Information" />
                <Divider />
                <CardContent>
                    {loading &&  <Loading size={24} />}
                    <br/>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    variant="outlined"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.name && !!formik.errors.name}
                                    helperText={formik.touched.name && formik.errors.name ? formik.errors.name : ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" fullWidth error={formik.touched.type && !!formik.errors.type}>
                                    <InputLabel htmlFor="type">Type</InputLabel>
                                    <Select
                                        native
                                        label="Type"
                                        value={formik.values.type}
                                        onChange={handleTypeChange}
                                        onBlur={formik.handleBlur}
                                    >
                                        <option aria-label="None" value="" />
                                        <option value="source">Source</option>
                                        <option value="sink">Sink</option>
                                    </Select>
                                    {formik.touched.type && formik.errors.type && (
                                        <Box color="error.main">{formik.errors.type}</Box>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" fullWidth error={formik.touched.topic && !!formik.errors.topic}>
                                    <InputLabel htmlFor="topic">Topic</InputLabel>
                                    <Select
                                        native
                                        label="Topic"
                                        name="topic"
                                        value={formik.values.topic}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    >
                                        <option aria-label="None" value="" />
                                        {kafkaTopicsState.map(topic => (
                                            <option key={topic.id} value={topic.id}>{topic.name}</option>
                                        ))}
                                    </Select>
                                    {formik.touched.topic && formik.errors.topic && (
                                        <Box color="error.main">{formik.errors.topic}</Box>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" fullWidth error={formik.touched.connectorClass && !!formik.errors.connectorClass}>
                                    <InputLabel htmlFor="connectorClass">Connector Class</InputLabel>
                                    <Select
                                        native
                                        label="Connector Class"
                                        name="connectorClass"
                                        value={formik.values.connectorClass}
                                        onChange={handleClassChange}
                                        onBlur={formik.handleBlur}
                                    >
                                        <option aria-label="None" value="" />
                                        {classOptions.map(option => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </Select>
                                    {formik.touched.connectorClass && formik.errors.connectorClass && (
                                        <Box color="error.main">{formik.errors.connectorClass}</Box>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" fullWidth error={formik.touched.valueConverter && !!formik.errors.valueConverter}>
                                    <InputLabel htmlFor="valueConverter">Value Converter</InputLabel>
                                    <Select
                                        native
                                        label="Value Converter"
                                        name="valueConverter"
                                        value={formik.values.valueConverter}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    >
                                        <option aria-label="None" value="" />
                                        {converterOptions.map(option => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </Select>
                                    {formik.touched.valueConverter && formik.errors.valueConverter && (
                                        <Box color="error.main">{formik.errors.valueConverter}</Box>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>

                        <br/>

                        <Collapse in={formik.values.type === 'source' && !useCustomConfig} timeout="auto" unmountOnExit>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl variant="outlined" fullWidth error={formik.touched.thing && !!formik.errors.thing}>
                                        <InputLabel htmlFor="thing">Thing</InputLabel>
                                        <Select
                                            native
                                            label="Thing"
                                            name="thing"
                                            value={formik.values.thing}
                                            onChange={handleThingChange}
                                            onBlur={formik.handleBlur}
                                        >
                                            <option aria-label="None" value="" />
                                            {thingsState.map(thing => (
                                                <option key={thing.thing_id} value={thing.thing_id}>{thing.name}</option>
                                            ))}
                                        </Select>
                                        {formik.touched.thing && formik.errors.thing && (
                                            <Box color="error.main">{formik.errors.thing}</Box>
                                        )}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormControl variant="outlined" fullWidth error={formik.touched.acl && !!formik.errors.acl}>
                                        <InputLabel htmlFor="acl">ACL</InputLabel>
                                        <Select
                                            native
                                            label="ACL"
                                            name="acl"
                                            value={formik.values.acl}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        >
                                            <option aria-label="None" value="" />
                                            {acls.map(acl => (
                                                <option key={acl.acl_id} value={acl.topic}>{acl.topic}</option>
                                            ))}
                                        </Select>
                                        {formik.touched.acl && formik.errors.acl && (
                                            <Box color="error.main">{formik.errors.acl}</Box>
                                        )}
                                    </FormControl>
                                    { formik.values.thing && acls.length === 0 && <>
                                        <span style={{color : 'red'}}> Add ACL to use this topic</span>
                                        <IconButton onClick={() => setAddingPermission(!addingPermission)}> <AddIcon /></IconButton>
                                        <Collapse in={addingPermission}>
                                            <Box display="flex" alignItems="center">
                                                <TextField
                                                    label="New Permission"
                                                    value={newPermission}
                                                    onChange={(e) => setNewPermission(e.target.value)}
                                                    fullWidth
                                                    variant="outlined"
                                                    margin="normal"
                                                />
                                                <IconButton onClick={handleAddPermission}><CheckIcon /></IconButton>
                                            </Box>
                                        </Collapse>
                                    </>
                                    }
                                </Grid>
                            </Grid>
                        </Collapse>

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox checked={useCustomConfig} onChange={handleCheckboxChange} name="useCustomConfig" />}
                                    label="Use Custom Configuration"
                                />
                            </Grid>
                        </Grid>

                        <Collapse in={useCustomConfig} timeout="auto" unmountOnExit>

                            <Grid container spacing={3}>

                                {  formik.values.type === 'source' ?  <>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label="Username"
                                                variant="outlined"
                                                name="username"
                                                value={formik.values.username}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.username && !!formik.errors.username}
                                                helperText={formik.touched.username && formik.errors.username ? formik.errors.username : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label="Password"
                                                variant="outlined"
                                                name="password"
                                                value={formik.values.password}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.password && !!formik.errors.password}
                                                helperText={formik.touched.password && formik.errors.password ? formik.errors.password : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label="Connection URL"
                                                variant="outlined"
                                                name="connectionUrl"
                                                value={formik.values.connectionUrl}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.connectionUrl && !!formik.errors.connectionUrl}
                                                helperText={formik.touched.connectionUrl && formik.errors.connectionUrl ? formik.errors.connectionUrl : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label="Source Topic"
                                                variant="outlined"
                                                name="sourceTopic"
                                                value={formik.values.sourceTopic}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.sourceTopic && !!formik.errors.sourceTopic}
                                                helperText={formik.touched.sourceTopic && formik.errors.sourceTopic ? formik.errors.sourceTopic : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControlLabel
                                                control={<Checkbox checked={useMqttSSL} onChange={handleMqttSSL} name="useMqttSSL" />}
                                                label="Use SSL"
                                            />
                                        </Grid>
                                    </>
                                    :
                                    formik.values.type === 'sink' ?
                                        <Grid item xs={12}>
                                            <FormControl fullWidth variant="outlined" error={formik.touched.dbname && !!formik.errors.dbname}>
                                                <InputLabel>Select database</InputLabel>
                                                <Select
                                                    label="Select database"
                                                    name="dbId"
                                                    value={formik.values.dbname}
                                                    onChange={handleDbChange}
                                                    onBlur={formik.handleBlur}
                                                >
                                                    <MenuItem key={'add.db'} value={'add.db'}>
                                                        {'Add new database'}
                                                    </MenuItem>
                                                    {databasesState.map((database) => (
                                                        <MenuItem key={database.id} value={database.id}>
                                                            {database.dbname}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {formik.touched.dbname && formik.errors.dbname && (
                                                    <p style={{ color: 'red' }}>{formik.errors.dbname}</p>
                                                )}
                                            </FormControl>
                                        </Grid> : null
                                }
                            </Grid>
                        </Collapse>

                        <Collapse in={useCustomConfig && useMqttSSL && formik.values.type === 'source' } timeout="auto" unmountOnExit>
                            <Grid item xs={12}>
                                <FileUpload />
                            </Grid>
                        </Collapse>

                        <Box display="flex" justifyContent="flex-end">
                            <Button type="submit" variant="contained" color="primary" className={classes.button}>
                                Save
                            </Button>
                        </Box>
                    </form>
                </CardContent>
            </Card>

            <DialogHandler
                open={openAddDb}
                onClose={ ()=> setOpenAddDb(false) }
                title="Database Information"
                contentText="Databases are used to store data received from Kafka server."
            >
                <AddForm  handleCloseNew={()=> setOpenAddDb(false)} handleAlert={handleAlert} setLoading={setLoading} />
            </DialogHandler>
        </>
    );
};

export default ConnectorForm;
