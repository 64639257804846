import React, { useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Divider
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {addDatabase, updateDatabase, withErrorHandlingAndLoading} from "../../constants/functions";
import {actionTypes} from "../../StateContext/types";
import {useStateValue} from "../../StateContext";

const validationSchema = Yup.object({
    dbname: Yup.string().required('Database Name is required'),
    dbtype: Yup.string().required('Database Type is required'),
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
    url: Yup.string().required('URL is required')
});

const AddForm = ({ handleCloseNew, db, setLoading, handleAlert }) => {

    const {databasesDispatch} = useStateValue();


    const handleAdd = withErrorHandlingAndLoading(async (db) => {
        const response = await addDatabase(db);
        await databasesDispatch({
            type: actionTypes.ADD_DATABASE,
            database: { id: response.insertId, ...db }
        });
        handleCloseNew();
    }, setLoading, handleAlert);

    const handleEdit = withErrorHandlingAndLoading(async (id,data) => {
        await updateDatabase(id, data);
        await databasesDispatch({
            type: actionTypes.UPDATE_DATABASE,
            DatabaseId: id,
            name: data
        });
    }, setLoading, handleAlert);

    const formik = useFormik({
        initialValues: {
            dbname: db?.dbname || '',
            dbtype: db?.dbtype || '',
            username: db?.username || '',
            password: db?.password || '',
            url: db?.url || ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log('Form submitted with values:', values);
            db ? handleEdit(db.id, values) : handleAdd(values);
        }
    });

    useEffect(() => {
        // Update formik's initialValues when db changes
        formik.setValues({
            dbname: db?.dbname || '',
            dbtype: db?.dbtype || '',
            username: db?.username || '',
            password: db?.password || '',
            url: db?.url || ''
        });
    }, [db]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Database Name"
                        variant="outlined"
                        name="dbname"
                        value={formik.values.dbname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.dbname && !!formik.errors.dbname}
                        helperText={formik.touched.dbname && formik.errors.dbname ? formik.errors.dbname : ''}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="outlined" error={formik.touched.dbtype && !!formik.errors.dbtype}>
                        <InputLabel>Database Type</InputLabel>
                        <Select
                            label="Database Type"
                            name="dbtype"
                            value={formik.values.dbtype}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="elasticsearch">Elasticsearch</MenuItem>
                            <MenuItem value="postgres">Postgres</MenuItem>
                        </Select>
                        {formik.touched.dbtype && formik.errors.dbtype && (
                            <p style={{ color: 'red' }}>{formik.errors.dbtype}</p>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Username"
                        variant="outlined"
                        name="username"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.username && !!formik.errors.username}
                        helperText={formik.touched.username && formik.errors.username ? formik.errors.username : ''}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Password"
                        variant="outlined"
                        type="password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.password && !!formik.errors.password}
                        helperText={formik.touched.password && formik.errors.password ? formik.errors.password : ''}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="URL"
                        variant="outlined"
                        name="url"
                        value={formik.values.url}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.url && !!formik.errors.url}
                        helperText={formik.touched.url && formik.errors.url ? formik.errors.url : ''}
                    />
                </Grid>
            </Grid>
            <DialogActions>
                <Button onClick={handleCloseNew} color="primary">
                    Cancel
                </Button>
                <Button type="submit" color="primary">
                    Submit
                </Button>
            </DialogActions>
        </form>
    )
};

export default AddForm;
