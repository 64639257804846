import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Paper } from '@material-ui/core';

import {
    TotalTopic,
    TotalConnectors,
    TotalApis,
    TotalThings,
    LatestSales,
    LatestMessages,
    LatestOrders,
} from './components';
import { getUser } from "../../Utils/common";
import { fetchDashboardInfo, withErrorHandlingAndLoading } from "../../constants/functions";
import { BarChart } from "../../Components/Charts";
import MessageAlert from "../../Components/Alert";

const useStyles = makeStyles(theme => ({
    paper: {
        color: theme.palette.text.secondary,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
}));

const Dashboard = ({ history, url }) => {
    const classes = useStyles();
    const [info, setInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [barChartData, setBarChartData] = useState([]);

    const [alert, setAlert] = useState({
        open: false,
        message: "",
        severity: "info"
    });

    const handleClose = () => {
        setAlert(prevState => ({ ...prevState, open: false }));
    };

    const handleAlert = (open, message, severity) => {
        setAlert({
            open: open,
            message: message,
            severity: severity
        });
    };

    const usr = getUser();

    useEffect(() => {
        const fetchData = withErrorHandlingAndLoading(async () => {
            const response = await fetchDashboardInfo();
            const processedData = response.topicsDetails.map(topic => ({
                label: topic.name,
                value: topic.messages_count,
            }));
            setBarChartData(processedData);
            setInfo(response);
        }, setLoading, handleAlert);

        fetchData();
    }, []);

    return (
        <>
            <MessageAlert
                open={alert.open}
                message={alert.message}
                onClose={handleClose}
                severity={alert.severity}
            />

            <Grid container spacing={3}>
                {usr.admin ? (
                    <>
                        <Grid item xs={12} md={6} lg={6}>
                            <LatestOrders url={url} history={history} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <LatestSales url={url} history={history} />
                        </Grid>
                    </>
                ) : null}

                <Grid item container spacing={2}>
                    {/* Total Topics */}
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <Paper className={classes.paper}>
                            <TotalTopic
                                loading={loading}
                                count={info?.topicsCount || 0}
                                history={history}
                            />
                        </Paper>
                    </Grid>
                    {/* Total Connectors */}
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <Paper className={classes.paper}>
                            <TotalConnectors
                                loading={loading}
                                sourceConnectorsCount={info?.sourceConnectorsCount || 0}
                                sinkConnectorsCount={info?.sinkConnectorsCount || 0}
                                httpConnectorsCount={info?.httpConnectorsCount || 0}
                                runningConnectorsCount={info?.runningConnectorsCount || 0}
                                failedConnectorsCount={info?.failedConnectorsCount || 0}
                                history={history}
                            />
                        </Paper>
                    </Grid>
                    {/* Total APIs */}
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <Paper className={classes.paper}>
                            <TotalApis
                                loading={loading}
                                apisCount={info?.apisCount || 0}
                                history={history}
                            />
                        </Paper>
                    </Grid>
                    {/* Total Things */}
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <Paper className={classes.paper}>
                            <TotalThings
                                loading={loading}
                                thingsCount={info?.thingsCount || 0}
                                history={history}
                            />
                        </Paper>
                    </Grid>
                </Grid>

                <Grid item container spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                        <LatestMessages
                            handleAlert={handleAlert}
                            topicsDetails={info?.topicsDetails || []}
                            history={history}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <BarChart data={barChartData} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Dashboard;
