import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    TextField,
    Grid,
} from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createFilterOptions } from '@material-ui/lab';
import { fetchKafkaTopics, withErrorHandlingAndLoading } from '../../../../constants/functions';
import { actionTypes } from '../../../../StateContext/types';
import { useStateValue } from '../../../../StateContext';
import Loading from "../../../../Components/Loading/Loading";
import Typography from "@material-ui/core/Typography";
import KafkaTopicForm from '../../../Topics/TopicForm';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    inputContainer: {
        width: '100%',
        maxWidth: 400,
        marginTop: 20,
    },
}));

const KafkaTopic = ({ values, setValues, handleAlert, setLoading, loading }) => {
    const classes = useStyles();
    const { kafkaTopicsState, kafkaTopicsDispatch } = useStateValue();
    const [dialogType, setDialogType] = useState(null);
    const openDialog = (type) => { setDialogType(type); };
    const closeDialog = () => { setDialogType(null); };

    const filter = createFilterOptions();

    useEffect(() => {
        const fetchData = withErrorHandlingAndLoading(async () => {
            const kafkaTopicsResponse = await fetchKafkaTopics();
            await kafkaTopicsDispatch({
                type: actionTypes.SET_TOPICS,
                value: kafkaTopicsResponse.topics,
            });
        }, setLoading, handleAlert);

        fetchData();
    }, [kafkaTopicsDispatch, setLoading, handleAlert]);

    const handlekafkaTopicChange = (event, newValue) => {
        if (newValue === 'Add New kafka Topic') {
            openDialog(0)
            setValues((prevValues) => ({ ...prevValues, topic: null })); // Reset the topic value
        } else {
            setValues((prevValues) => ({
                ...prevValues,
                topic: {
                    ...newValue,
                    name: newValue?.name,
                },
            }));
        }
    };

    return (
        <div className={classes.root}>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Typography variant={'h6'}>
                        kafkaTopic & Permission
                    </Typography>
                    {loading && <Loading />}
                    <div className={classes.inputContainer}>
                        <Autocomplete
                            size="small"
                            value={values.topic || null} // Ensure value is null when no topic is selected
                            onChange={handlekafkaTopicChange}
                            options={['Add New kafka Topic', ...kafkaTopicsState]}
                            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                            renderInput={(params) => (
                                <TextField {...params} label="kafkaTopic" variant="outlined" fullWidth />
                            )}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                return filtered;
                            }}
                            clearOnBlur
                        />
                    </div>
                </Grid>
            </Grid>
                <KafkaTopicForm
                    dialogType={dialogType}
                    kafkaTopicsDispatch={kafkaTopicsDispatch}
                    handleAlert={handleAlert}
                    closeDialog={ closeDialog}
                />
        </div>
    );
};

KafkaTopic.propTypes = {
    values: PropTypes.object.isRequired,
    setValues: PropTypes.func.isRequired,
    handleAlert: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
};

export default KafkaTopic;
