import {Divider, Grid, Typography} from "@material-ui/core";
import React from "react";

export const DetailsTab = ({selectData}) => (
    <Grid container spacing={3} style={{margin: '10px'}}>
        <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary" component="p">
                Type
            </Typography>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="h6" color="textSecondary" component="h4">
                {"MQTT Broker"}
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Divider/>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary" component="p">
                Created
            </Typography>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="h6" color="textSecondary" component="h4">
                {selectData.created_at}
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Divider/>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary" component="p">
                Updated
            </Typography>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="h6" color="textSecondary" component="h4">
                {selectData.updated_at}
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Divider/>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary" component="p">
                Device Id
            </Typography>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="h6" color="textSecondary" component="h4">
                {selectData.device_id}
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Divider/>
        </Grid>
    </Grid>
);