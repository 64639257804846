import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Divider,
    Button,
} from '@material-ui/core';

const DialogHandler = ({open, onClose, title, contentText, onConfirm, confirmText, children }) => {
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="generic-dialog-title">
            <DialogTitle id="generic-dialog-title">{title}</DialogTitle>
            <Divider />
            <DialogContent>
                {contentText && (
                    <DialogContentText>
                        {contentText}
                    </DialogContentText>
                )}
                {children}
            </DialogContent>
            <DialogActions>
                {
                    onConfirm &&  <>
                        <Button onClick={onClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={onConfirm} color="primary">
                            {confirmText || "Confirm"}
                        </Button>
                    </>
                }
            </DialogActions>
        </Dialog>
    );
};

export default DialogHandler;
