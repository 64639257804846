// CustomTabs.js
import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from '@material-ui/core';

export const AntTabs = ({ value, onChange, ...rest }) => (
    <Tabs value={value} onChange={onChange} {...rest} />
);

export const AntTab = ({ label, ...rest }) => (
    <Tab label={label} {...rest} />
);

export const TabPanel = ({ children, value, index, ...rest }) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...rest}
    >
        {value === index && (
            <div>
                {children}
            </div>
        )}
    </div>
);

AntTabs.propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired
};

AntTab.propTypes = {
    label: PropTypes.string.isRequired
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
