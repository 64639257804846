import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
    Card,
    CardHeader,
    CardContent,
    Button,
    Divider,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import {resumeTopics, withErrorHandlingAndLoading} from "../../../../constants/functions";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
    },
    content: {
        padding: 0,
    },
    actions: {
        justifyContent: "flex-end",
    },
    progress: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(2),
    },
    tableContainer: {
        overflow: 'auto',
        '& .MuiTableCell-root': {
            padding: '10px', // Adjust the padding as needed
        },
        '& .MuiPaper-elevation4': {
            boxShadow: '0 0 0 0', // Adjust the padding as needed
        },
    },
}));

const LatestMessages = ({ topicsDetails, history, handleAlert }) => {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedTopicIds, setSelectedTopicIds] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleResume = withErrorHandlingAndLoading(async () => {
        await resumeTopics(selectedTopicIds);
        await handleDialogClose()
        await  handleAlert(true , "Topics Resumed successfully" , 'success');
    }, setLoading , handleAlert);

    const handleResumeClick = (id) => {
        setSelectedTopicIds([id]);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    return (
        <Card className={classes.root}>
            <CardHeader
                title="Latest Messages"
                action={
                    <Button color="primary" size="small" variant="text" onClick={() => history.push("topics")}>
                        View all <ArrowRightIcon />
                    </Button>
                }
            />
            <Divider />
            <CardContent className={classes.content}>
                <Table className={classes.tableContainer}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Topic Name</TableCell>
                            <TableCell>Messages Count</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {topicsDetails?.map((topic) => (
                            <TableRow key={topic.id}>
                                <TableCell>{topic.name}</TableCell>
                                <TableCell>{topic.messages_count}</TableCell>
                                <TableCell align="right">
                                    <Tooltip title={'Resume topic'}>
                                        <IconButton size="small" onClick={() => handleResumeClick(topic.id)}>
                                            <PlayCircleFilledWhiteIcon fontSize={"large"} />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>

            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Resume Topic</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to resume the topic ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleResume} color="primary">
                        Resume
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
};

LatestMessages.propTypes = {
    className: PropTypes.string,
    topicsDetails: PropTypes.array.isRequired,
    url: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
};

export default LatestMessages;
