import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        padding: theme.spacing(3),
    },
    formField: {
        marginBottom: theme.spacing(2),
    },
    formButton: {
        marginTop: theme.spacing(2),
    },
}));

const ConfigForm = ({ initialValues, onSubmit }) => {
    const classes = useStyles();

    const validationSchema = Yup.object({
        name: Yup.string().required('Name is required'),
        topics: Yup.string().required('Topics are required'),
        connectorClass: Yup.string().required('Connector Class is required'),
    });

    const extractAfterFirstUnderscore = (str) => {
        const underscoreIndex = str?.indexOf('_');
        return underscoreIndex === -1 ? '' : str?.substring(underscoreIndex + 1);
    };

    const initialFormValues = {
        ...initialValues,
        topics: extractAfterFirstUnderscore(initialValues.topics),
    };

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ values, handleChange, handleBlur, handleSubmit, touched, errors, isSubmitting }) => (
                <Form onSubmit={handleSubmit} className={classes.formContainer}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Name"
                                variant="outlined"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.name && !!errors.name}
                                helperText={touched.name && errors.name ? errors.name : ''}
                                className={classes.formField}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Topics"
                                variant="outlined"
                                name="topics"
                                value={values.topics}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.topics && !!errors.topics}
                                helperText={touched.topics && errors.topics ? errors.topics : ''}
                                className={classes.formField}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Connector Class"
                                variant="outlined"
                                name="connectorClass"
                                value={values.connectorClass}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.connectorClass && !!errors.connectorClass}
                                helperText={touched.connectorClass && errors.connectorClass ? errors.connectorClass : ''}
                                className={classes.formField}
                            />
                        </Grid>
                    </Grid>

                </Form>
            )}
        </Formik>
    );
};

export default ConfigForm;

/**
 *   <Button
 type="submit"
 variant="contained"
 color="primary"
 disabled={isSubmitting}
 className={classes.formButton}
 >
 Submit
 </Button>
 */
