import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { DropzoneArea } from 'material-ui-dropzone';
import {
    Card,
    CardActions,
    CardContent,
    Avatar,
    Typography,
    Divider,
    Button,
    LinearProgress
} from '@material-ui/core';
import { getToken, getUser, setUserSession, getRole } from '../../../../Utils/common';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import axios from 'axios';
import { Progress } from 'reactstrap';
import MuiAlert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme => ({
    root: {},
    details: {
        display: 'flex'
    },
    avatar: {
        height: 110,
        width: 100
    },
    dialog: {
        padding: theme.spacing(2),
    },
    dropzoneClass: {
        background: '#212121',
        fontSize: 12,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    progress: {
        marginTop: theme.spacing(2),
        textAlign: 'center',
    },
    dialogActions: {
        justifyContent: 'flex-end',
    },
    button: {
        marginLeft: theme.spacing(1),
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AccountProfile = props => {
    const { className, url, ...rest } = props;
    const classes = useStyles();
    const [files, setFiles] = React.useState([]);
    const [load, setLoad] = React.useState(0);
    const user = getUser();


    const postFiles = () => {
        const data = new FormData();
        for (var x = 0; x < files.length; x++) data.append('file', files[x]);
        axios.post(`${url}/updatePhoto/` + files[0].name, data, {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() },
            onUploadProgress(progressEvent) {
                setLoad(progressEvent.loaded / progressEvent.total * 100);
            }
        }).then(res => {
            console.log(res);
            const urlImage = res.data.location;
            setImagePath(urlImage);
            user.location = urlImage;
            setUserSession(getToken(), user, {});
            handleClose();
            setNot(true);
        });
    };

    const [open, setOpen] = React.useState(false);
    const [not, setNot] = React.useState(false);
    const [imagePath, setImagePath] = React.useState(user.location);
    const handleChange = (files) => setFiles(files);
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    const handleCloseNot = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setNot(false);
    };

    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Snackbar open={not} autoHideDuration={6000} onClose={handleCloseNot}>
                <Alert onClose={handleCloseNot} severity="success">
                    Info updated successfully
                </Alert>
            </Snackbar>

            <CardContent>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid onClick={handleOpen} item style={{ position: 'relative', display: 'inline-block' }}>
                        <Avatar className={classes.avatar} src={url + '/' + imagePath} />
                        <IconButton
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                backgroundColor: 'white', // Optional: Change the background color for better visibility
                                border: '2px solid white'  // Optional: Add a border for the button
                            }}
                            onClick={handleOpen}
                        >
                            <EditIcon />
                        </IconButton>
                    </Grid>
                </Grid>

                <Grid style={{marginTop:10}} container justifyContent="center" alignItems="center">
                    <Grid item>
                        <Typography gutterBottom variant="body1" align="center">
                            {user.name}
                        </Typography>
                        <Typography className={classes.locationText} color="textSecondary" variant="body1" align="center">
                            {user.email}
                        </Typography>
                    </Grid>
                </Grid>

                <div className={classes.progress}>
                    <LinearProgress value={100} variant="determinate" />
                </div>
            </CardContent>
            <Divider />


            <Dialog open={open} onClose={handleClose} classes={{ paper: classes.dialog }}>
                <DialogTitle>Upload Image</DialogTitle>
                <DialogContent dividers>
                    <DropzoneArea
                        onChange={handleChange}
                        dropzoneClass={classes.dropzoneClass}
                        acceptedFiles={['image/*']}
                        showPreviews
                        showPreviewsInDropzone
                        maxFileSize={5000000}
                    />
                    <div className={classes.progress}>
                        <Progress max="100" color="success" value={load}>
                            {Math.round(load, 2)}%
                        </Progress>
                    </div>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button onClick={handleClose} color="default" variant="outlined" className={classes.button}>
                        Cancel
                    </Button>
                    <Button onClick={postFiles} color="primary" variant="contained" className={classes.button}>
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>

        </Card>
    );
};

AccountProfile.propTypes = {
    className: PropTypes.string
};

export default AccountProfile;
