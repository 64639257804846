import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";

const CustomToolbar = ({openDialog}) => {

    const handleClick = () => {
        openDialog(0);
    };

    return (
        <React.Fragment>
            <Tooltip title={"Add Topic"}>
                <IconButton  onClick={handleClick}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
        </React.Fragment>
    );
};

export default CustomToolbar;
