import {Card, CardContent, Grid} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {convertIntoDate} from "../constants/functions";
import ReactJson from "react-json-view";
import React from "react";

export const MessageCard = ({name, message , handleAlert}) => {

    const parsedMessage = (() => {
        try {
            return JSON.parse(message.message);
        } catch (e) {
            return message.message;
        }
    })();

    return (
        <Card>
            <Box style={{padding: '0 5px'}} display="flex" justifyContent="space-between" alignItems="center">
                {
                    parsedMessage.creationDate &&
                    <Typography variant="h6"> Date : {convertIntoDate(Number(parsedMessage.creationDate))} </Typography>
                }
            </Box>
            <CardContent>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={12}>
                        <ReactJson
                            name={name}
                            src={parsedMessage}
                            enableClipboard={(copy) => {
                                navigator.clipboard.writeText(JSON.stringify(copy.src, null, 2));
                                handleAlert(true, 'Copied to clipboard', 'success');
                            }}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};