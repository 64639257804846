import React, {useEffect, useState} from "react";
import {fetchAclRecords, withErrorHandlingAndLoading} from "../../../constants/functions";
import {Button, Divider, Grid} from "@material-ui/core";
import Loading from "../../../Components/Loading/Loading";
import CustomList from "../CustomList";
import {PermissionForm} from "./PermissionForm";

export const PermissionsTab = ({selectData, handleAlert}) => {
    const [Acls, setAcls] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState({add: false, delete: false});

    useEffect(() => {
        fetchDataAcl();
    }, []);

    const fetchDataAcl = withErrorHandlingAndLoading(async () => {
        setLoading(true);
        const response = await fetchAclRecords(selectData.thing_id);
        setAcls(response);
    },setLoading, handleAlert);


    const handleOpenDialog = (type) => {
        setOpenDialog((prevState) => ({
            ...prevState,
            [type]: true,
        }));
    };

    const handleCloseDialog = () => {
        setOpenDialog({add: false, delete: false});
    };

    return (
        <Grid container spacing={3} style={{margin: '10px'}}>
            <Grid item xs={6}>
                <Button variant="contained" color="primary" onClick={() => handleOpenDialog('add')}>
                    Assign New Permission
                </Button>
            </Grid>
            <Divider/>
            <Grid item xs={10}>
                {
                    loading ?
                    <Loading/>
                    :
                    <CustomList
                        handleAlert={handleAlert}
                        setAcls={setAcls}
                        Acls={Acls}
                        thingId={selectData.thing_id}
                        fetchDataAcl={fetchDataAcl}
                    />
                }
            </Grid>

            {/* Add ACL Dialog */}
            <PermissionForm { ...{ openDialog, handleCloseDialog, selectData , setLoading, handleAlert , fetchDataAcl }} />

        </Grid>
    );
};