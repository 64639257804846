import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {
    Card, CardHeader, IconButton,
} from '@material-ui/core';

import {getToken} from "../../../../Utils/common";
import {Line} from "react-chartjs-2";
import "chartjs-plugin-streaming";
import axios from "axios";
import RefreshIcon from "@material-ui/icons/Refresh";

const useStyles = makeStyles(() => ({
    root: {
        height: '100%'
    },
    content: {
        padding: 0
    },
    image: {
        height: 48,
        width: 48
    },
    actions: {
        justifyContent: 'flex-end'
    }
}));


const Charts = props => {
    const {className, url, history, ...rest} = props;

    const classes = useStyles();
    const [progress, setprogress] = useState(true)
    const [data, setdata] = useState({}
    )
    const [min, setMin] = useState(10)
    const options = {
        scales: {
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: '# of Messages',
                },
                ticks: {
                    max: min,
                    min: 0
                }
            }],


            xAxes: [

                {

                    type: "realtime",
                    realtime: {
                        onRefresh: function () {

                            axios.get(`${url}/messages/`, {
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + getToken()
                                }
                            }).then(res => {

                                if (res) {

                                    console.log(Date)
                                    var i = 0;
                                    var arrd = []
                                    var arrc = []


                                    for (let j = 0; j < res.data.length; j++) {
                                        arrd.push({
                                            x: parseInt(res.data[j].date),
                                            y: parseInt(res.data[j].messages)
                                        })
                                        if (j == 0) {
                                            arrd.push({
                                                x: parseInt(res.data[j].date),
                                                y: parseInt(0)

                                            })
                                        } else {
                                            arrc.push({
                                                x: parseInt(res.data[j].date),
                                                y: parseInt(res.data[j].messages - res.data[j - 1].messages)

                                            })
                                        }

                                    }
                                    if (res.data.length > 0) {
                                        data.datasets[0].data = arrd;
                                        data.datasets[1].data = arrc;
                                        setMin(res.data[res.data.length - 1].messages + 3)
                                    }


                                }
                            })
                        }

                        , duration: 86400000,
                        delay: 10000,
                        refresh: 1800000,
                    }
                }
            ]
        }
        , tooltips: {
            mode: 'nearest',
            intersect: false
        },
        hover: {
            mode: 'nearest',
            intersect: false
        },
        legend: {
            position: 'right'
        }
    };

    function getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }


    const fetchData = async () => {
        try {
            let res = await fetch(`${url}/nbrmessage/`,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            setprogress(false)
        } catch (err) {
            console.log('fetch data error ' + err)
        }
    }

    const fetchDatamessage = async () => {
        try {
            let res = await fetch(`${url}/messages/`,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            var arrd = []
            var arrc = []

            for (let j = 0; j < myjson.length; j++) {
                arrd.push({
                    x: parseInt(myjson[j].date),
                    y: parseInt(myjson[j].messages)
                })
                if (j == 0) {
                    arrc.push({
                        x: parseInt(myjson[j].date),
                        y: parseInt(0)
                    })
                } else {
                    arrc.push({
                        x: parseInt(myjson[j].date),
                        y: parseInt(myjson[j].messages - myjson[j - 1].messages)
                    })
                }
            }

            setMin(myjson[myjson.length - 1].messages + 3)
            setdata(
                {
                    datasets: [{
                        label: "All Messages",
                        borderColor: `rgba(5, 5, 255, 0.5)`,
                        backgroundColor: `rgba(255, 126, 139, 0.5)`,
                        fill: false,
                        cubicInterpolationMode: 'monotone',
                        data: arrd
                    }, {
                        label: "New Messages ",
                        borderColor: `rgba(255, 99, 132,0.5)`,
                        backgroundColor: `rgba(255, 126, 139, 0.5)`,
                        fill: false,
                        cubicInterpolationMode: 'monotone',
                        data: arrc
                    }]


                })
        } catch (err) {
            console.log('fetch data error ' + err)
        }
    }


    useEffect(() => {
        fetchData()
    }, []);
    useEffect(() => {
        fetchDatamessage()
    }, []);

    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardHeader
                title="All Messages"
                action={
                    <IconButton size="small">
                        <RefreshIcon onClick={fetchData}/>
                    </IconButton>
                }
            />
            <Line data={data} options={options} style={{"height": "200px"}}/>

        </Card>
    );
};

Charts.propTypes = {
    className: PropTypes.string
};

export default Charts;
