import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Avatar,
    LinearProgress
} from '@material-ui/core';
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined';
import CardActionArea from "@material-ui/core/CardActionArea";
import {getToken} from "../../../../Utils/common";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    content: {
        alignItems: 'center',
        display: 'flex'
    },
    title: {
        fontWeight: 700
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        height: 56,
        width: 56
    },
    icon: {
        height: 32,
        width: 32
    }, difference: {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        height: "64px"

    },
    progress: {
        marginTop: theme.spacing(3)
    }
}));

const TotalTemplates = props => {
    const {className, url, history, ...rest} = props;

    const classes = useStyles();
    const [values, setValues] = useState([])
    const fetchData = async () => {
        try {
            let res = await fetch(`${url}/getSchema/`,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            await setValues(myjson);
        } catch (err) {
            console.log('fetch data error ' + err)
        }
    }

    const goto = () => {
        history.push('schema');

    }
    useEffect(() => {
        fetchData()
    }, []);
    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardActionArea onClick={goto}>
                <CardContent>
                    <Grid
                        container
                        justify="space-between"
                    >
                        <Grid item>
                            <Typography
                                className={classes.title}
                                color="textSecondary"
                                gutterBottom
                                variant="body2"
                            >
                                TEMPLATES
                            </Typography>
                            <Typography variant="h3">{values.length}</Typography>
                        </Grid>
                        <Grid item>
                            <Avatar className={classes.avatar}>
                                <InsertChartIcon className={classes.icon}/>
                            </Avatar>
                        </Grid>
                    </Grid>
                    <Grid xs={12}>
                        <div className={classes.difference}></div>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

TotalTemplates.propTypes = {
    className: PropTypes.string
};

export default TotalTemplates;
