import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Drawer,
    Divider,
} from '@material-ui/core';
import SidebarNav from "./components/Sidebar";

const drawerWidth = 240 ;

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
}));

const DrawerComponent = ({ open, pages, history }) => {
    const classes = useStyles();
    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerHeader}></div>
            <Divider />
            <SidebarNav
                className={classes.nav}
                pages={pages}
                history={history}
            />
        </Drawer>
    );
};

DrawerComponent.propTypes = {
    open: PropTypes.bool.isRequired,
    handleDrawerClose: PropTypes.func.isRequired,
    pages: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
};

export default DrawerComponent;
