import React, {useEffect, useState} from 'react';
import clsx from "clsx";
import { TextField, Grid, Card, CardContent, Button, CardHeader, CardActions, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { listBillingAddress} from "../../../../constants/users";
import { withErrorHandlingAndLoading } from "../../../../constants/functions";
import Loading from "../../../../Components/Loading";
import { actionTypes } from "../../../../StateContext/types";
import { useStateValue } from "../../../../StateContext";
import AddressForm from '../Forms/AddressForm'; // Import your AddressDialog component

const useStyles = makeStyles(() => ({
    root: {},
    centeredMessage: {
        textAlign: 'center',
        marginTop: '16px',
    },
    headerActions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

const BillingAddress = (props) => {
    const { className, handleAlert, ...rest } = props;
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const { billingAddressesDispatch, billingAddressesState } = useStateValue();

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog= () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        const getAddresses = withErrorHandlingAndLoading(async () => {
            const resp = await listBillingAddress();
            await billingAddressesDispatch({ type: actionTypes.SET_ADDRESSES, value:  resp });
        }, setLoading, handleAlert);
        getAddresses();
    },[]);

    return (
        <>
            <Card sx={{ height: '100%' }} {...rest} className={clsx(classes.root, className)}>
                <CardHeader
                    title={
                        <div className={classes.headerActions}>
                            <span>Billing Address</span>
                            <Button variant="contained" color="primary" onClick={handleOpenDialog}>
                                Add Address
                            </Button>
                        </div>
                    }
                />
                <CardContent>
                    {
                        loading ? <Loading size={24}/> :
                            billingAddressesState?.length === 0 ? (
                                <Typography variant="body1" className={classes.centeredMessage}>
                                    No billing addresses available.
                                </Typography>
                            ) : (
                                <Grid container spacing={3}>
                                    {billingAddressesState?.map((address, index) => (
                                        <Grid item xs={12} md={6} lg={3} key={index}>
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <div>{address.firstname} {address.lastname}</div>
                                                    <div>{address.address_line1}</div>
                                                    <div>{address.address_city}, {address.address_zip}</div>
                                                    <div>{address.phone}</div>
                                                    <div>{address.address_country}</div>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            )
                    }
                </CardContent>
            </Card>
            <AddressForm handleAlert={handleAlert} open={dialogOpen} onClose={handleCloseDialog} />
        </>
    );
}

export default BillingAddress;
