import React, {useEffect, useState} from 'react';
import {Doughnut} from 'react-chartjs-2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    IconButton,
    Divider,
    Typography, Avatar, Grid
} from '@material-ui/core';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import RefreshIcon from '@material-ui/icons/Refresh';
import TabletMacIcon from '@material-ui/icons/TabletMac';
import MoneyIcon from '@material-ui/icons/Money';
import Box from "@material-ui/core/Box";
import Iframe from 'react-iframe'
import CircularProgress from "@material-ui/core/CircularProgress";
import {getToken} from "../../../../Utils/common";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    chartContainer: {
        position: 'relative',
        height: '150px'
    }, title: {
        fontWeight: 700
    },
    stats: {
        marginTop: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center'
    },
    device: {
        textAlign: 'center',
        padding: theme.spacing(1)
    },
    deviceIcon: {
        color: theme.palette.icon
    }
}));

const UsersByDevice = props => {
    const {className, url, ...rest} = props;

    const classes = useStyles();
    const theme = useTheme();


    const options = {
        legend: {
            display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        cutoutPercentage: 80,
        layout: {padding: 0},
        tooltips: {
            enabled: true,
            mode: 'index',
            intersect: false,
            borderWidth: 1,
            borderColor: theme.palette.divider,
            backgroundColor: theme.palette.white,
            titleFontColor: theme.palette.text.primary,
            bodyFontColor: theme.palette.text.secondary,
            footerFontColor: theme.palette.text.secondary
        }
    };


    const [values, setValues] = useState([])
    const [mqtt, setmqtt] = useState(0)
    const [http, sethttp] = useState(0)
    const [elastic, setelastic] = useState(0)
    var i = 0, j = 0, k = 0;
    const fetchDataConfig = async (name) => {
        try {
            let res = await fetch(`${url}/connectorConfig/` + name,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            console.log("oki")
            if (myjson["connector.class"] == "com.test.HttpSourceConnector") {

                i++
            } else if (myjson["connector.class"] == "com.test.MqttSourceConnector") {
                j++
            } else if (myjson["connector.class"] == "io.confluent.connect.elasticsearch.ElasticsearchSinkConnector") {
                k++
            }
            setelastic(k)
            sethttp(i)
            setmqtt(j)
        } catch (err) {
            console.log('fetch data error ' + err)
        }
    }
    const fetchDataType = async () => {
        try {
            let res = await fetch(`${url}/allConnectors/`,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            await setValues(myjson);
            await myjson.map(val => {

                fetchDataConfig(val.name)


            })


        } catch (err) {
            console.log('fetch data error ' + err)
        }
    }
    const devices = [
        {
            title: 'HTTP',
            value: http,
            icon: <MoneyIcon/>,
            color: theme.palette.primary.main
        },
        {
            title: 'MQTT',
            value: mqtt,
            icon: <MoneyIcon/>,
            color: theme.palette.error.main
        },
        {
            title: 'ELASTIC',
            value: elastic,
            icon: <MoneyIcon/>,
            color: theme.palette.warning.main
        }
    ];

    useEffect(() => {
        fetchDataType()
    }, []);

    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >


            <CardContent>
                <Grid item>
                    <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                        variant="body2"
                    >
                        CONNECTOR TYPES
                    </Typography>
                    <div className={classes.stats}>

                        {devices.map(device => (
                            <div
                                className={classes.device}
                                key={device.title}
                            >


                                <Typography variant="body1">{device.title}</Typography>
                                <Typography
                                    style={{color: device.color}}
                                    variant="h6"
                                >
                                    {device.value}
                                </Typography>
                            </div>
                        ))}

                    </div>
                </Grid>

            </CardContent>
        </Card>
    );
};

UsersByDevice.propTypes = {
    className: PropTypes.string
};

export default UsersByDevice;
