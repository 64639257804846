import LinearProgress from "@material-ui/core/LinearProgress";
import { Tooltip, IconButton, makeStyles } from '@material-ui/core';
import PausePresentationIcon from "@material-ui/icons/PausePresentation";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import DeleteIcon from "@material-ui/icons/Delete";
import EmailIcon from "@material-ui/icons/Email";
import StopIcon from "@material-ui/icons/Stop";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import * as PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center', // Align items vertically
    },
    button: {
        margin: '0 4px', // Adjust the margin between buttons as needed
    },
});

export function CustomToolbarSelect(props) {
    const classes = useStyles();
    return <>

        <div className={classes.container}>
            <Tooltip title={"Pause Topic(s)"}>
                <IconButton onClick={props.onClick} className={classes.button}>
                    <PausePresentationIcon/>
                </IconButton>
            </Tooltip>

            <Tooltip title={"Resume Topic(s)"}>
                <IconButton onClick={props.onClick1} className={classes.button}>
                    <SlideshowIcon/>
                </IconButton>
            </Tooltip>

            <Tooltip title={"Delete Topic(s)"}>
                <IconButton onClick={props.onClick2} className={classes.button}>
                    <DeleteIcon/>
                </IconButton>
            </Tooltip>

            <Tooltip title={"See Messages"}>
                <IconButton onClick={props.onClick3} disabled={props.selectedRows.data.length > 1} className={classes.button}>
                    <EmailIcon/>
                </IconButton>
            </Tooltip>

            <Tooltip title={props.streaming ? "Stop Streaming" : "Start Streaming"}>
                <IconButton onClick={props.onClick4} className={classes.button}>
                    {props.streaming ? <StopIcon/> : <PlayArrowIcon/>}
                </IconButton>
            </Tooltip>
        </div>

        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>Confirmation</DialogTitle>
            <DialogContent dividers>
                Are you sure you want to delete this template?
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={props.onClick5}>Yes</Button>
                <Button onClick={props.onClose} color="primary">No</Button>
            </DialogActions>
        </Dialog>
    </>;
}

CustomToolbarSelect.propTypes = {
    onClick: PropTypes.func,
    onClick1: PropTypes.func,
    onClick2: PropTypes.func,
    onClick3: PropTypes.any,
    selectedRows: PropTypes.any,
    streaming: PropTypes.any,
    onClick4: PropTypes.func,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onClick5: PropTypes.func
};