import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Avatar, CircularProgress, IconButton } from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        padding: theme.spacing(2),
        cursor: 'pointer'
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.text.secondary,
        fontWeight: 700
    },
    avatar: {
        backgroundColor: theme.palette.success.main,
        height: 56,
        width: 56
    },
    icon: {
        marginLeft: theme.spacing(1)
    },
    difference: {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        height: '64px'
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    }
}));

const TotalTopics = ({ loading, count, history }) => {
    const classes = useStyles();

    const goto = () => {
        history.push('topics');
    };

    return (
        <div className={classes.root} onClick={goto}>
            <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
            >
                TOPICS
                <IconButton onClick={goto}>
                    <ArrowForwardIcon className={classes.icon} />
                </IconButton>
            </Typography>
            <Grid container justifyContent="space-between">
                <Grid item>
                   { loading ? (
                           <div className={classes.loadingContainer}>
                               <CircularProgress />
                           </div>
                       ) : <Typography color="inherit" variant="h3">
                                {count}
                            </Typography>
                   }
                </Grid>
                <Grid item>
                    <Avatar className={classes.avatar}>
                        <FolderIcon />
                    </Avatar>
                </Grid>
            </Grid>
        </div>
    );
};

TotalTopics.propTypes = {
    loading: PropTypes.bool.isRequired,
    count: PropTypes.number.isRequired,
    history: PropTypes.object.isRequired
};

export default TotalTopics;
