import axios from "axios";
import {base_url, errorHandler} from "./functions";

const connectors_url = base_url + '/connectors';

export const fetchAllConnectors = async () => {
    try {
        const response = await axios.get(`${connectors_url}`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}
export const addConnector = async (connector) => {
    try {
        const response = await axios.post(`${connectors_url}`, connector);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}
export const deleteConnector = async (id) => {
    try {
        const response = await axios.delete(`${connectors_url}/${id}`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}
export const fetchConnectorData = async (name) => {
    try {
        const response = await axios.get(`${connectors_url}/${name}`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}