import React , {useState} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Divider,
    TextField,
    Box,
    Button,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addThing, withErrorHandlingAndLoading } from '../../../constants/functions';
import { actionTypes } from '../../../StateContext/types';
import { useStateValue } from '../../../StateContext';
import Loading from "../../../Components/Loading/Loading";

const validationSchema = Yup.object({
    thingName: Yup.string().required('Thing Name is required'),
});

const ThingForm = ({ open, handleAlert, handleCloseNew , thingName}) => {

    const { thingsDispatch } = useStateValue();

    const [loading, setLoading] = useState(false);

    const handleAdd = withErrorHandlingAndLoading(async (values) => {
        const response = await addThing({ name: values.thingName });
        await thingsDispatch({
            type: actionTypes.ADD_THING,
            thing: response.newThing
        });
        handleAlert(true, 'Thing added successfully' , 'success');
        handleCloseNew();
    }, setLoading, handleAlert);

    const formik = useFormik({
        initialValues: {
            thingName: thingName || '',
        },
        validationSchema,
        onSubmit: (values) => {
            handleAdd(values);
        },
    });

    return (
        <Dialog open={open} onClose={handleCloseNew}>
            <DialogTitle> New Thing </DialogTitle>
            <Divider />
            <DialogContent>
                <DialogContentText>
                    Things are used to allow you to connect a pure MQTT client to thingwings
                </DialogContentText>
                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Thing Name"
                        name="thingName"
                        type="text"
                        fullWidth
                        value={formik.values.thingName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.thingName && Boolean(formik.errors.thingName)}
                        helperText={formik.touched.thingName && formik.errors.thingName}
                    />
                    <DialogActions>
                        <Button onClick={handleCloseNew} color="primary">
                            Cancel
                        </Button>
                        <Button type="submit" color="primary">
                            Add
                        </Button>
                        {
                            loading && <Loading size={24}/>
                        }
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default ThingForm;
