import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography, IconButton, Paper } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MessageAlert from "../../../../Components/Alert";
import {handleUpload} from "../../../../constants/functions";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    },
    fileInput: {
        display: 'none',
    },
    uploadButton: {
        marginTop: theme.spacing(2),
    },
}));

const FileUpload = () => {
    const classes = useStyles();
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [alert, setAlert] = useState({
        open : false , message : "" , severity : ""
    });
    const [open, setOpen] = React.useState(false);
    const [files, setFiles] = useState(Array.from({ length: 3 }, () => null));
    const names = ['SSL Cert','SSL Key','CA'];

    const handleFileChange = (index, event) => {
        const newFiles = [...files];
        newFiles[index] = event.target.files[0];
        setFiles(newFiles);
    };

    const uploadFiles = async () => {
        try{
            const resp = handleUpload(files,setUploadPercentage);
            setAlert({open : true , message : "Files uploaded successfully" , severity : "success"});
        } catch(error) {
            console.log(error);
            setOpen(true);
            setAlert({open : true , message : "Error uploading files" , severity : "error"});
        }
    }

    return (
        <div className={classes.root}>

            <MessageAlert open={alert.open} message={alert.message} onClose={setOpen(false)} severity={alert.severity}/>

            <Grid container spacing={3}>
                {files.map((file, index) => (
                    <Grid item xs={12} sm={4} key={index}>
                        <Paper className={classes.paper}>
                            <IconButton disabled component="label">
                                <AttachFileIcon />
                                <input
                                    type="file"
                                    onChange={(e) => handleFileChange(index, e)}
                                    className={classes.fileInput}
                                />
                            </IconButton>
                            <Typography variant="body2" component="p">
                                {file ? file.name : 'Choose file'}
                            </Typography>
                            <Typography variant="body2" component="p">
                                {names[index]}
                            </Typography>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
            <Button
                disabled
                variant="contained"
                color="primary"
                startIcon={<CloudUploadIcon />}
                className={classes.uploadButton}
                onClick={ uploadFiles }
                //disabled={files.some((file) => !file)}
            >
                Upload
            </Button>
        </div>
    );
};

export default FileUpload;
