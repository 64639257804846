import uuid from 'uuid/v1';

export default [

    {
        id: uuid(),
        title: 'Api 1 ',
        description:
            'Medium is an online publishing platform developed by Evan Williams, and launched .',
        imageUrl: '/images/products/product_2.png',
        totalDownloads: '625',
        createdAt: '31/03/2019',
        port: "127.0.0.1:4000"
    },
    {
        id: uuid(),
        title: 'Api 2',
        description:
            'Slack is a cloud-based set of team collaboration tools and serv.',
        imageUrl: '/images/products/product_3.png',
        totalDownloads: '857',
        createdAt: '03/04/2019',
        port: "127.0.0.1:5000"
    }
];
