import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {Button} from '@material-ui/core';

import {SearchInput} from '../../../../Components';
import axios from "axios";
import {values} from "underscore";

const useStyles = makeStyles(theme => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    searchInput: {
        marginRight: theme.spacing(1)
    }
}));

const ProductsToolbar = props => {
    const {className, history, url, setValues, values, ...rest} = props;


    const classes = useStyles();

    const routeChange = () => {
        let path = `apidetails`;
        history.push(path);
    }
    const [search, setSearch] = useState({
        name: '',
    });

    const handleChange = event => {
        if (event.target.value != "") {
            setSearch({
                ...values,
                [event.target.name]: event.target.value
            });
            console.log(values)
            setValues(values.filter(val => val.modelName.includes(event.target.value)))
        } else {

            fetchData()
        }
    };
    const fetchData = async () => {
        try {
            let res = await fetch(`${url}/allApi/`,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json'}
                });
            let myjson = await res.json();
            await setValues(myjson);
        } catch (err) {
            alert('fetch data error ' + err)
        }
    }

    useEffect(() => {
        fetchData()
    }, []);
    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <div className={classes.row}>
                <span className={classes.spacer}/>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={routeChange}
                >
                    Add Api
                </Button>
            </div>
            <div className={classes.row}>
                <SearchInput
                    className={classes.searchInput}
                    placeholder="Search Api "
                    onChange={handleChange}
                    name={"search"}
                    values={search}
                />
            </div>
        </div>
    );
};

ProductsToolbar.propTypes = {
    className: PropTypes.string
};

export default ProductsToolbar;
