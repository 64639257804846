import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Iframe from "react-iframe";
import Box from "@material-ui/core/Box";
import { getToken } from "../../Utils/common";
import Loading from "../../Components/Loading/Loading";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
        height: "75vh"
    },
    myClassname: {
        border: "0"
    }
}));

const Nodered = props => {
    const classes = useStyles();
    const token = getToken();
    const [isLoaded, setIsLoaded] = useState(false);
    function handleIframeLoad() {setIsLoaded(true);}
    let containerName = props.match.params.id;

    return (
        <div className={classes.root} id="root">
            <Box display="flex" justifyContent="center">
                {isLoaded ? <></> : <Loading/>}
            </Box>
            
            <Iframe url={`${process.env.REACT_APP_DNS_URL}/flow-editor/${containerName}/?token=${token}`}
                    width="100%"
                    height="100%"
                    className={classes.myClassname}
                    position="relative"
                    onLoad={handleIframeLoad}
                    allowFullScreen
            />
        </div>
    );
};

export default Nodered;
