import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/styles';
import {IconButton, Grid, Typography} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import axios from "axios"
import {ProductsToolbar, ProductCard} from './components';
import uuid from 'uuid/v1';
import mockData from './data';
import Iframe from "react-iframe";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    },
    pagination: {
        marginTop: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    }
}));


const Swagger = props => {
    const {history, url} = props;
    const classes = useStyles();


    return (
        <div className={classes.root}>
            <Iframe url="https://www.thingwings.com:4000/apis"
                    width="100%"
                    height="800px"
                    id="myId"
                    className="myClassname"
                    display="initial"
                    position="relative"/>
        </div>
    );
};

export default Swagger;
