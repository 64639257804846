import React, { useState, useEffect, useCallback } from 'react';
import { Line } from 'react-chartjs-2';
import {Card, CardHeader, Paper, Typography} from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { fetchMessageCounts, getRandomColor } from "../../constants/functions";

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        height: '100%',
        overflow : 'auto',
        padding: 10
    },
    chartContainer: {
        position: 'relative',
        padding: '10px',
        height: '80%',
        margin : '0px auto'
    },
}));

const LineGraph = ({ selectedTopicsIds, streaming, className }) => {
    const classes = useStyles();

    const [graphData, setGraphData] = useState({
        datasets: [],
    });

    // Function to update graph data based on received topicsWithMessageCount
    const updateGraphData = useCallback((topicsWithMessageCount) => {
        setGraphData(prevGraphData => {
            const updatedData = { ...prevGraphData };
            const receivedLabels = topicsWithMessageCount.map(topic => topic.name);

            // Filter out datasets not present in current topicsWithMessageCount
            updatedData.datasets = updatedData.datasets.filter(dataset =>
                receivedLabels.includes(dataset.label)
            );

            // Update or add new datasets with new data points
            topicsWithMessageCount.forEach(topic => {
                const datasetIndex = updatedData.datasets.findIndex(dataset =>
                    dataset.label === topic.name
                );

                if (datasetIndex !== -1) {
                    updatedData.datasets[datasetIndex].data.push({
                        x: Date.now(),
                        y: topic.messages_count
                    });
                } else {
                    updatedData.datasets.push({
                        label: topic.name,
                        fill: false,
                        borderColor: getRandomColor(),
                        data: [{ x: Date.now(), y: topic.messages_count }],
                    });
                }
            });

            return updatedData;
        });
    }, []);

    // Function to fetch and update data periodically
    const onGraphRefresh = useCallback(async () => {
        if (streaming && selectedTopicsIds.length !== 0) {
            try {
                const response = await fetchMessageCounts(selectedTopicsIds);
                updateGraphData(response);
            } catch (error) {
                console.error('Error fetching message counts:', error);
            }
        }
    }, [streaming, selectedTopicsIds, updateGraphData]);

    // Setup interval for periodic data refresh
    useEffect(() => {
        let refreshInterval;

        if (streaming && selectedTopicsIds.length !== 0) {
            refreshInterval = setInterval(onGraphRefresh, 10000); // Adjust interval as needed
        } else {
            clearInterval(refreshInterval);
            setGraphData({ datasets: [] }); // Reset data when not streaming
        }

        return () => clearInterval(refreshInterval);
    }, [streaming, selectedTopicsIds, onGraphRefresh]);

    // Chart.js options configuration
    const options = {
        maintainAspectRatio: false,
        scales: {
            yAxes: [{}], // Optional configurations for yAxes
            xAxes: streaming ? [{
                type: 'realtime',
                realtime: {
                    delay: 1000,
                    duration: 80000,
                    refresh: 10000
                }
            }] : [{}],
        },
        tooltips: {
            mode: 'nearest',
            intersect: false,
        },
        hover: {
            mode: 'nearest',
            intersect: false,
        },
        legend: {
            position: 'right',
        },
    };

    return (
        <Card className={classes.root}>
            <Typography style={{marginBottom:'10px'}} variant={"h6"}> Messages Count  </Typography>
            <div className={classes.chartContainer}>
                <Line data={graphData} options={options} />
            </div>
        </Card>
    );
};

export default LineGraph;
