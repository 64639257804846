import React from 'react';
import { Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link } from '@material-ui/core';

const InvoiceItem = ({ invoice }) => {
    const formatCurrency = (amount, currency) => {
        return `${(amount / 100).toFixed(2)} ${currency.toUpperCase()}`;
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };

    const getFrequency = (invoice) => {
        const plan = invoice.lines.data.find(line => line.plan && line.plan.interval);
        return plan ? plan.plan.interval.charAt(0).toUpperCase() + plan.plan.interval.slice(1) : '—';
    };

    return (
        <TableRow>
            <TableCell>{formatCurrency(invoice.amount_paid, invoice.currency)}</TableCell>
            <TableCell>{invoice.currency.toUpperCase()}</TableCell>
            <TableCell>{invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1)}</TableCell>
            <TableCell>{getFrequency(invoice)}</TableCell>
            <TableCell>{invoice.number}</TableCell>
            <TableCell>{formatDate(invoice.created)}</TableCell>
            <TableCell>
                <Link href={invoice.invoice_pdf} target="_blank" rel="noopener">Download PDF</Link>
            </TableCell>
        </TableRow>
    );
};

const InvoiceList = ({ invoices }) => {
    return (
        <Paper style={{ padding: '16px', marginBottom: '16px' }}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Amount Paid</TableCell>
                            <TableCell>Currency</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Frequency</TableCell>
                            <TableCell>Invoice Number</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoices.map(invoice => (
                            <InvoiceItem key={invoice.id} invoice={invoice} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default InvoiceList;
