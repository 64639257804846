import React, {useState} from "react";
import MUIDataTable from "mui-datatables";
import {useStateValue} from "../../StateContext";
import {actionTypes} from "../../StateContext/types";
import {deleteTopics, pauseTopics, resumeTopics} from "../../constants/functions";
import CustomToolbar from "./CustomToolbar";
import {makeStyles} from '@material-ui/styles';
import {CustomToolbarSelect} from "./CustomToolbarSelect";
import Loading from "../../Components/Loading/Loading";

const useStyles = makeStyles(() => ({
    tableContainer: {
        overflow: 'auto',
        '& .MuiTableCell-root': {
            padding: '5px', // Adjust the padding as needed
        },
        '& .MuiPaper-elevation4': {
            boxShadow: '0 0 0 0', // Adjust the padding as needed
        },
    },
}));

const TopicsListTable = ({ selectedTopicsIds, setSelectedTopicsIds,streaming, setStreaming, handleAlert, listMessages, openDialog}) => {

    const classes = useStyles();
    const { kafkaTopicsState, kafkaTopicsDispatch } = useStateValue();
    const [open, setOpen] = useState(false);
    const [loading,setLoading] = useState(false);

    const handlePause = async () => {
        try {
            setLoading(true);
             await pauseTopics(selectedTopicsIds);
            handleAlert(true , "Topics Paused successfully" , 'success');
        } catch (error) {
            console.log(error);
            handleAlert(true , "Error pausing kafka topics, Please try again ;" , 'error')
        } finally {
            setLoading(false)
        }
    };

    const handleResume = async () => {
        try {
            setLoading(true);
            await resumeTopics(selectedTopicsIds);
            handleAlert(true , "Topics Resumed successfully" , 'success');
        } catch (error) {
            console.log(error)
            handleAlert(true , "Error resuming kafka topics, Please try again ;" , 'error')
        } finally {
            setLoading(false)
        }
    };

    const handleDelete = async (setSelectedRows) => {
        try {
            const deletePromises = await deleteTopics(selectedTopicsIds);
            const deletedTopics = await Promise.all(deletePromises);

            await kafkaTopicsDispatch({
                type: actionTypes.DELETE_TOPICS,
                topicIds: deletedTopics
            });

            setOpen(false);

            setSelectedRows([]);

            handleAlert(true , "topic  deleted successfully" , 'success');
        } catch (error) {
            console.error("Error deleting topics:", error);
            handleAlert(true , "error  deleting topics" , 'error');
        }
    };

    const handleCloseDelete = () => {
        setOpen(false);
    };

    const columns = [
        {
            label: 'Id',
            name: 'id',
            options: {
                display: false,
            },
        },
        {
            label: 'Name',
            name: 'name',
            options: {
                responsivePriority: 1,
            },
        },
        {
            label: 'N° Partitions',
            name: 'partitions',
            options: {
                responsivePriority: 2,
            },
        },
        {
            label: 'Messages Number',
            name: 'messages_count',
            options: {
                responsivePriority: 3,
            },
        },
        {
            label: 'Status',
            name: 'status',
            options: {
                filter: true,
                responsivePriority: 4,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const backgroundColor = value === 'connected' ? 'green' : value === 'not_connected' ? 'red' : 'transparent';
                    const color = 'white'; // Text color to make it readable against the background
                    const style = {
                        backgroundColor,
                        color,
                        padding: '4px 10px', // More padding for a better appearance
                        borderRadius: '12px', // Larger border radius for a smoother look
                        boxShadow: '0 2px 5px rgba(0,0,0,0.2)', // Subtle shadow for depth
                        display: 'inline-block' // Ensure span does not expand to full width
                    };
                    return (
                        <p style={style}>
                            {value === 'connected' ? 'Connected' : value === 'not_connected' ? 'Not Connected' : value}
                        </p>
                    );
                },
            },
        }

    ];

    const options = {
        filter: true,
        selectableRows: 'multiple',
        filterType: 'dropdown',
        responsive: 'standard',
        rowsPerPage: 10,
        textLabels: {
            body: {
                noMatch: loading ? <Loading /> : "Sorry, no matching records found",
            },
        },
        isLoading: loading,
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
            return (
                <CustomToolbarSelect
                     selectedRows={selectedRows}
                     streaming={streaming}
                     open={open}
                     onClose={handleCloseDelete}
                     onClick={handlePause}
                     onClick1={handleResume}
                     onClick2={() => setOpen(true)}
                     onClick4={() => setStreaming(!streaming)}
                     onClick5={() => handleDelete(setSelectedRows)}
                     onClick3={ async () => {
                            selectedTopicsIds.length === 1 &&
                            await listMessages(selectedTopicsIds[0])
                        }
                     }
                />
            );
        },
        customToolbar : () =>  <CustomToolbar openDialog={openDialog}/>,
        onRowsSelect: (currentRowsSelected , allRowsSelected ) => {
            if (allRowsSelected.length !== 0) {
                const ids = allRowsSelected.map( (row) => kafkaTopicsState[row.index]?.id );
                setSelectedTopicsIds(ids);
            }
            else {
                setSelectedTopicsIds([])
            }
        },
    };

    return (

            <MUIDataTable
                title={"Topics List"}
                className={classes.tableContainer}
                data={kafkaTopicsState}
                columns={columns}
                options={options}
            />

    );
};

export default TopicsListTable;
