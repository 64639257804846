import React from "react";

export const actionTypes = {
    EDIT_FORM_VALUE: 'EDIT_FORM_VALUE',
    EDIT_METADATA_VALUES: 'EDIT_METADATA_VALUES',
    SET_METADATA_VALUES: 'SET_METADATA_VALUES',
    EMPTY_FORM_VALUE: 'EMPTY_FORM_VALUE',

    SET_PRODUCTS: 'SET_PRODUCTS',
    ADD_PRODUCT: 'ADD_PRODUCT',
    UPDATE_PRODUCT: 'UPDATE_PRODUCT',
    ARCHIVE_PRODUCT: 'ARCHIVE_PRODUCT',

    SET_USERS: 'SET_USERS',
    ADD_USER: 'ADD_USER',
    UPDATE_USER: 'UPDATE_USER',
    DELETE_USER: 'DELETE_USER', 
    SET_CONNECTORS:'SET_CONNECTORS',
    UPDATE_CONNECTOR: 'UPDATE_CONNECTOR',
    ADD_CONNECTOR: 'ADD_CONNECTOR',
    DELETE_CONNECTOR: 'DELETE_CONNECTOR',
    SET_CONNECTOR_INFO: 'SET_CONNECTOR_INFO',
    SET_TOPICS: 'SET_TOPICS',
    ADD_TOPIC: 'ADD_TOPIC',
    DELETE_TOPIC: 'DELETE_TOPIC',
    UPDATE_TOPIC: 'UPDATE_TOPIC',
    SET_THINGS: 'SET_THINGS',
    ADD_THING: 'ADD_THING',
    DELETE_THING: 'DELETE_THING',
    UPDATE_THING: 'UPDATE_THING',
    DELETE_TOPICS: 'DELETE_TOPICS',
    SET_DATABASES: 'SET_DATABASES',
    ADD_DATABASE: 'ADD_DATABASE',
    DELETE_DATABASE: 'DELETE_DATABASE',
    UPDATE_DATABASE: 'UPDATE_DATABASE',
    SET_NOTIFICATIONS: 'SET_NOTIFICATIONS', 
    ADD_NOTIFICATION: 'ADD_NOTIFICATION',
    DELETE_NOTIFICATIONS: 'DELETE_NOTIFICATIONS',
    UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
    SET_FLOWS: 'SET_FLOWS',
    ADD_FLOW: 'ADD_FLOW',
    DELETE_FLOW: 'DELETE_FLOW',
    UPDATE_FLOW: 'UPDATE_FLOW',
    OPEN_DRAWER: 'OPEN_DRAWER',
    CLOSE_DRAWER: 'CLOSE_DRAWER',
    DELETE_FLOWS: 'DELETE_FLOWS',
    SET_APIS: 'SET_APIS',
    ADD_API: 'ADD_API',
    DELETE_API: 'DELETE_API',
    DELETE_APIS: 'DELETE_APIS',
    UPDATE_API: 'UPDATE_API',
    SET_SOURCES: 'SET_SOURCES',
    ADD_SOURCE: 'ADD_SOURCE',
    DELETE_SOURCE: 'DELETE_SOURCE',
    DELETE_SOURCES: 'DELETE_SOURCES',
    SET_ADDRESSES: 'SET_ADDRESSES',
    ADD_ADDRESS: 'ADD_ADDRESS',
    DELETE_ADDRESS: 'DELETE_ADDRESS',
    UPDATE_ADDRESS: 'UPDATE_ADDRESS'


};