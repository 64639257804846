import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {getToken} from "../../Utils/common";
import { ArrowBack, ArrowForward } from '@material-ui/icons';

import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import {
    convertIntoDate,
    deleteDatabasesMapping,
    fetchDatabasesMapping,
    fetchSourceMessages,
    withErrorHandlingAndLoading
} from "../../constants/functions";

import Loading from "../../Components/Loading/Loading";
import {SmallCard} from "../../Components/SmallCard";
import {MessageCard} from "../../Components/MessageCard";
import Alert from "@material-ui/lab/Alert";
import {useStateValue} from "../../StateContext";
import {actionTypes} from "../../StateContext/types";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(theme => ({
    item: {
        maxHeight: '70vh', // Set your desired height
        overflow: 'auto',
    },
    demo: {
        height: '100%', // Make sure the card takes the full height of the parent grid
        display: 'flex',
        flexDirection: 'column',
    },
    tableContainer: {
        flexGrow: 1,
        overflow: 'auto',
        '& .MuiTableCell-root': {
            padding: '15px', // Adjust the padding as needed
        },
        '& .MuiPaper-elevation4': {
            boxShadow: '0 0 0 0', // Adjust the padding as needed
        },
    },
    fallbackMessage: {
        textAlign: 'center',
        marginTop: theme.spacing(4),
        color: theme.palette.text.secondary,
    },
    paginationControls: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(3),
    },
    pageInfo: {
        margin: theme.spacing(0, 2),
    },
}));

const Sources = ({ url, handleAlert }) => {

    const classes = useStyles();
    const {sourcesState, sourcesDispatch} = useStateValue();
    const [source, setSource] = useState({id : '', name :''});
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState({});
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [totalMessages, setTotalMessages] = useState(0);
    const [fetchTrigger, setFetchTrigger] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deletedId, setDeletedId] = React.useState(null);

    const [dialogType, setDialogType] = useState(null);
    const openDialog = (type) => { setDialogType(type); };
    const closeDialog = () => { setDialogType(null); };

    const handleDeleteContinue = async () => {
        try {
            let res = await fetch(`${url}/delete/` + deletedId.name + "/" + deletedId.type + "/" + deletedId.id, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
            })
            let myjson = await res.json();
            myjson = myjson.filter(js => js._id != deletedId.id)
            closeDialog()

        } catch (error) {
            console.error('Error error:', error)
        }
    }

    const handleSourceDelete = withErrorHandlingAndLoading(async () => {
        await deleteDatabasesMapping(deletedId);
        await sourcesDispatch({
            type : actionTypes.DELETE_SOURCE ,
            sourceId : deletedId
        })
        closeDialog()
    },setLoading , handleAlert)

    const handleDeleteALL = async () => {
        try {
            let res = await fetch(`${url}/deleteALLIndex/` + deletedId.name, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
            })

            closeDialog()
        } catch (error) {
            console.error('Error error:', error)
        }
    }

    useEffect(() => {
        const getDatabasesMapping = withErrorHandlingAndLoading(async () => {
            const sources = await fetchDatabasesMapping();
            sources.length > 0 && await setSource({id : sources[0].id , name : sources[0].source_name});
            await sourcesDispatch({
                type : actionTypes.SET_SOURCES,
                value : sources
            })
        }, setLoading , handleAlert);

        getDatabasesMapping()
    }, []);

    useEffect(() => {
        const fetchMessages = withErrorHandlingAndLoading(async () => {
            const initialState = {fetchedMessages : [] , total : 0};
            const { messages: fetchedMessages, total } = source.id ?  await fetchSourceMessages(source.id, page, pageSize) : initialState;
            setMessages(fetchedMessages);
            setTotalMessages(total);
        }, setLoading, handleAlert);

        if (source.id) {
            fetchMessages();
        }
    }, [page, pageSize, source.id , fetchTrigger]);

    const handlePlayClick = (id, name) => {
        setMessages([]);
        setSource({ id, name });
        setFetchTrigger(prev => !prev);
    };

    const handleOpenClick = (message) => {
        setMessage({message : message._source || message})
        openDialog(3)
    };

    const handleDeleteClick = (message) => {
        setMessage(message)
        openDialog(3)
    };

    const handleNextPage = () => {
        if (page * pageSize < totalMessages) {
            setPage(page + 1);
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    return (
        <>
            <Grid container spacing={3}>
                <Grid className={classes.item} item lg={5} md={6} sm={12} xs={12}>
                    <TableContainer component={Paper} className={classes.tableContainer}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell >ID</TableCell>
                                    <TableCell>Source Type</TableCell>
                                    <TableCell>Source Name</TableCell>
                                    <TableCell >Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sourcesState.map((val) => (
                                    <TableRow key={val.id} selected={val.id === source.id}>
                                        <TableCell>{val.id}</TableCell>
                                        <TableCell component="th" scope="row">{val.dbname}</TableCell>
                                        <TableCell component="th" scope="row">{val.source_name}</TableCell>
                                        <TableCell>
                                            <IconButton aria-label="play" onClick={() => handlePlayClick(val.id ,val.source_name)}>
                                                <PlaylistPlayIcon />
                                            </IconButton>
                                            <IconButton
                                                aria-label="delete"
                                                onClick={() => {
                                                    setDeletedId(val.id);
                                                    openDialog(1);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid className={classes.item} item lg={7} md={6} sm={12} xs={12}>
                    {loading ? (
                        <Loading/>
                    ) : (
                        <>
                            <Grid style={{maxHeight : '90%', overflow : 'auto'}}  container spacing={3}>
                                {messages?.length === 0 ? (
                                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Typography className={classes.fallbackMessage} variant="caption">
                                            No messages available for this source
                                        </Typography>
                                    </Grid>
                                ) : (
                                    messages?.map((message, index) => (
                                        <Grid item lg={4} md={6} sm={12} xs={12} key={index}>
                                            <SmallCard
                                                name={source.name}
                                                timestamp={message._source ? convertIntoDate(message._source.creationDate) : convertIntoDate(message.creationDate)}
                                                message={message}
                                                onOpen={() => handleOpenClick(message)}
                                                onDelete={() => handleDeleteClick(message)}
                                            />
                                        </Grid>
                                    ))
                                )}
                            </Grid>

                           { messages?.length !== 0 &&
                               <div className={classes.paginationControls}>
                                    <IconButton
                                        color="primary"
                                        onClick={handlePreviousPage}
                                        disabled={page === 1}
                                        size={"small"}
                                    >
                                        <ArrowBack />
                                    </IconButton>
                                    <Typography variant="caption" className={classes.pageInfo}>
                                        Page {page} of {Math.ceil(totalMessages / pageSize)}
                                    </Typography>
                                    <IconButton
                                        color="primary"
                                        onClick={handleNextPage}
                                        disabled={page * pageSize >= totalMessages}
                                        size={"small"}
                                    >
                                        <ArrowForward />
                                    </IconButton>
                                </div>
                           }
                        </>
                    )}
                </Grid>
            </Grid>

            <Dialog open={dialogType === 0} onClose={closeDialog}>
                <DialogTitle disableTypography={false}>Confirmation</DialogTitle>
                <DialogContent dividers>
                    Are you sure you want to delete this data ?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteContinue} color='primary' size='small'> yes </Button>
                    <Button onClick={closeDialog} color='primary' size='small'> No </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={dialogType === 1} onClose={closeDialog}>
                <DialogTitle disableTypography={false}>Confirmation</DialogTitle>
                <DialogContent dividers>
                    Are you sure you want to delete this source ?
                    <Alert severity={"error"} > Note that deleting the source will delete all of its data !! </Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSourceDelete} color='primary' size='small'> yes </Button>
                    <Button onClick={closeDialog} color='primary' size='small'> No </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={dialogType === 2} onClose={closeDialog}>
                <DialogTitle disableTypography={false}>Confirmation</DialogTitle>
                <DialogContent dividers>
                    Are you sure you want to delete the index definitely ?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteALL} color='primary' size='small'> yes </Button>
                    <Button onClick={closeDialog} color='primary' size='small'> No </Button>
                </DialogActions>

            </Dialog>

            <Dialog open={dialogType === 3} onClose={closeDialog}>
                <DialogTitle disableTypography={false}>{source.name}</DialogTitle>
                <DialogContent dividers>
                    <MessageCard
                        name={source.name}
                        message={ message._source ? message._source : message }
                        handleAlert={handleAlert}
                    />
                </DialogContent>
            </Dialog>

        </>
    );
};

export default Sources;
