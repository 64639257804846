import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from "@material-ui/core";
import { withErrorHandlingAndLoading } from "../../constants/functions";
import { actionTypes } from "../../StateContext/types";
import { useStateValue } from "../../StateContext";
import { addUser, deleteUser, updateUser } from "../../constants/users";
import Loading from "../../Components/Loading/Loading";

const UserForm = ({ open, onClose, initialValues, handleAlertOpen }) => {
    const [loading, setLoading] = useState(false);
    const { usersDispatch } = useStateValue();

    const validationSchema = Yup.object({
        name: Yup.string().required("Name is required"),
        username: Yup.string().required("Username is required"),
        email: Yup.string().email("Invalid email").required("Email is required"),
        organization: Yup.string().required("Organization is required"),
        password: Yup.string()
            .min(6, "Password must be at least 6 characters")
            .required("Password is required"),
    });

    const handleAddUser = withErrorHandlingAndLoading(async (values) => {
        const response = await addUser(true, values);
        usersDispatch({ type: actionTypes.ADD_USER, value: {id : response.id , ...values }});
        handleAlertOpen(true, "User added successfully", "success");
        onClose();
    }, setLoading, handleAlertOpen);

    const handleEditUser = withErrorHandlingAndLoading(async (values) => {
        await updateUser(true, values);
        usersDispatch({ type: actionTypes.UPDATE_USER, userId: initialValues.id , value : values});
        handleAlertOpen(true, "User updated successfully", "success");
        onClose();
    }, setLoading, handleAlertOpen);

    const handleDeleteUser = withErrorHandlingAndLoading(async () => {
        await deleteUser(initialValues.id);
        usersDispatch({ type: actionTypes.DELETE_USER, userId: initialValues.id });
        handleAlertOpen(true, "User deleted successfully", "success");
        onClose();
    }, setLoading, handleAlertOpen);

    const handleSubmit = async (values) => {
        if (open === 'EDIT') {
            await handleEditUser(values);
        } else if (open === 'ADD') {
            await handleAddUser(values);
        }
    };


    return (
        <>
            {open === 'DELETE' ? (
                <Dialog open={true} onClose={onClose} maxWidth="xs" fullWidth>
                    <DialogTitle>Confirm Deletion</DialogTitle>
                    <DialogContent>
                        <Typography>Are you sure you want to delete this user?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleDeleteUser} color="secondary">
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : open === 'ADD' || open === 'EDIT' ?  (
                <Dialog open={Boolean(open)} onClose={onClose} maxWidth="sm" fullWidth>
                    <DialogTitle>{open === "EDIT" ? "Edit User" : "Add User"}</DialogTitle>
                    <DialogContent>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ values, handleChange, handleBlur, errors, touched }) => (
                                <Form>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                as={TextField}
                                                name="name"
                                                label="Name"
                                                fullWidth
                                                variant="outlined"
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touched.name && !!errors.name}
                                                helperText={touched.name && errors.name && (
                                                    <Typography variant="body2" color="error">
                                                        {errors.name}
                                                    </Typography>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Field
                                                as={TextField}
                                                name="username"
                                                label="Username"
                                                fullWidth
                                                variant="outlined"
                                                value={values.username}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touched.username && !!errors.username}
                                                helperText={touched.username && errors.username && (
                                                    <Typography variant="body2" color="error">
                                                        {errors.username}
                                                    </Typography>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                as={TextField}
                                                name="email"
                                                label="Email"
                                                fullWidth
                                                variant="outlined"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touched.email && !!errors.email}
                                                helperText={touched.email && errors.email && (
                                                    <Typography variant="body2" color="error">
                                                        {errors.email}
                                                    </Typography>
                                                )}
                                            />
                                        </Grid>
                                       { open ==='ADD' &&
                                            <Grid item xs={12}>
                                                <Field
                                                    as={TextField}
                                                    name="organization"
                                                    label="Organization"
                                                    fullWidth
                                                    variant="outlined"
                                                    value={values.organization}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={touched.organization && !!errors.organization}
                                                    helperText={touched.organization && errors.organization && (
                                                        <Typography variant="body2" color="error">
                                                            {errors.organization}
                                                        </Typography>
                                                    )}
                                                />
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <Field
                                                as={TextField}
                                                name="password"
                                                label="Password"
                                                type="password"
                                                fullWidth
                                                variant="outlined"
                                                value={values.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touched.password && !!errors.password}
                                                helperText={touched.password && errors.password && (
                                                    <Typography variant="body2" color="error">
                                                        {errors.password}
                                                    </Typography>
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                    <DialogActions>
                                        <Button onClick={onClose} color="primary">
                                            Cancel
                                        </Button>
                                        <Button disabled={loading} type="submit" color="primary">
                                            {open === "EDIT" ? "Update" : "Add"}
                                        </Button>
                                        {
                                            loading && <Loading size={24}/>
                                        }
                                    </DialogActions>
                                </Form>
                            )}
                        </Formik>
                    </DialogContent>
                </Dialog>
            ) : null
            }
        </>
    );
};

export default UserForm;
