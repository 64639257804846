import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import { getUser } from './Utils/common';

const Layout = ({ routes }) => {
    const user = getUser();
    const isAuthenticated = Boolean(user);
    const isAdmin = Boolean( user?.admin);

    return (
        <Switch>
            {routes.map((route, index) => {
                const LayoutComponent = route.layout === 'MainLayout' ? MainLayout : MinimalLayout;
                return (
                    <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        render={props => {
                            // Redirect authenticated users away from sign-in/sign-up pages
                            if (isAuthenticated && (route.path === '/signin' || route.path === '/signup' || route.path === '/')) {
                                return <Redirect to="/dashboard" />;
                            }

                            // Redirect unauthenticated users to sign-in page if route requires authentication
                            if (route.requiresAuth && !isAuthenticated) {
                                return <Redirect to="/signin" />;
                            }

                            if (isAuthenticated && !isAdmin && route.path === '/users'){
                                return <Redirect to="/not-found" />;
                            }

                            return (
                                <LayoutComponent>
                                    <route.component {...props} />
                                </LayoutComponent>
                            );
                        }}
                    />
                );
            })}
            <Route render={() => <Redirect to="/not-found" />} />
        </Switch>
    );
};

export default Layout;
