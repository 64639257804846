import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2),
    },
    content: {
        textAlign: 'center',
    },
    image: {
        marginTop: 50,
        display: 'inline-block',
        maxWidth: '100%',
        width: 240,
    },
    button: {
        marginTop: 20,
    },
}));

const NotFound = () => {
    const classes = useStyles();
    const history = useHistory();

    const handleHomeClick = () => {
        history.push('/');
    };

    return (
        <div className={classes.root}>
            <Grid container direction="column" alignItems="center" spacing={4}>
                <Grid item>
                    <Typography variant="h3">
                        The page you are looking for isn’t here
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle2">
                        You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.
                    </Typography>
                </Grid>
                <Grid item>
                    <img
                        alt="Under development"
                        className={classes.image}
                        src="/images/undraw_page_not_found_su7k.svg"
                    />
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleHomeClick}
                    >
                        Return to Home
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default NotFound;
