import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Grid, Card} from "@material-ui/core";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {withErrorHandlingAndLoading} from "../../../../constants/functions";
import {addBillingAddress} from "../../../../constants/users";
import {actionTypes} from "../../../../StateContext/types";
import {useStateValue} from "../../../../StateContext";
import Loading from "../../../../Components/Loading";

const AddressForm = ({ open, onClose, handleAlert }) => {

    const { billingAddressesDispatch } = useStateValue();
    const [loading, setLoading] = useState(false);

    const handleSubmit = withErrorHandlingAndLoading(async (values) => {
        const resp = await addBillingAddress(values);
        await billingAddressesDispatch({ type: actionTypes.ADD_ADDRESS, value: { ...values, id: resp.id } });
    }, setLoading, handleAlert);

    const formik = useFormik({
        initialValues: {
            firstname: '',
            lastname: '',
            phone: '',
            address_line1: '',
            address_line2: '',
            address_state: '',
            address_zip: '',
            address_country: 'FR'
        },
        validationSchema: Yup.object({
            firstname: Yup.string().required('Required'),
            lastname: Yup.string().required('Required'),
            address_line1: Yup.string().required('Required'),
            address_zip: Yup.string().required('Required'),
            address_state: Yup.string().required('Required'),
            phone: Yup.string().required('Required'),
            address_country: Yup.string().required('Required'),
        }),
        onSubmit: async (values) => { await handleSubmit(values) }
    });

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Add New Billing Address</DialogTitle>
            <DialogContent Divider>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="First Name"
                                name="firstname"
                                variant="outlined"
                                required
                                fullWidth
                                value={formik.values.firstname}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                                helperText={formik.touched.firstname && formik.errors.firstname}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Last Name"
                                name="lastname"
                                variant="outlined"
                                required
                                fullWidth
                                value={formik.values.lastname}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                                helperText={formik.touched.lastname && formik.errors.lastname}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Street Address 1"
                                name="address_line1"
                                variant="outlined"
                                required
                                fullWidth
                                value={formik.values.address_line1}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.address_line1 && Boolean(formik.errors.address_line1)}
                                helperText={formik.touched.address_line1 && formik.errors.address_line1}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Street Address 2 (optional)"
                                name="address_line2"
                                variant="outlined"
                                fullWidth
                                value={formik.values.address_line2}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Postal Code"
                                name="address_zip"
                                variant="outlined"
                                required
                                fullWidth
                                value={formik.values.address_zip}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.address_zip && Boolean(formik.errors.address_zip)}
                                helperText={formik.touched.address_zip && formik.errors.address_zip}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="City"
                                name="address_state"
                                variant="outlined"
                                required
                                fullWidth
                                value={formik.values.address_state}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.address_state && Boolean(formik.errors.address_state)}
                                helperText={formik.touched.address_state && formik.errors.address_state}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Phone"
                                name="phone"
                                variant="outlined"
                                required
                                fullWidth
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.phone && Boolean(formik.errors.phone)}
                                helperText={formik.touched.phone && formik.errors.phone}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Country"
                                name="address_country"
                                variant="outlined"
                                required
                                fullWidth
                                value={formik.values.address_country}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.address_country && Boolean(formik.errors.address_country)}
                                helperText={formik.touched.address_country && formik.errors.address_country}
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} variant={"contained"} onClick={onClose} color="secondary">Cancel</Button>
                <Button disabled={loading} variant={"contained"} onClick={formik.handleSubmit} color="primary">Submit</Button>
                {loading  && <Loading size={12} />}
            </DialogActions>
        </Dialog>
    );
};

export default AddressForm;
