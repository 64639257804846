import {useStateValue} from "../../StateContext";
import React, {useCallback, useState} from "react";
import {addFlow, updateFlow, withErrorHandlingAndLoading} from "../../constants/functions";
import {actionTypes} from "../../StateContext/types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@material-ui/core";
import Loading from "../../Components/Loading";
import {Alert} from "@material-ui/lab";

export const FlowForm = ({flowControl, name, setProgress, handleAlert, handleDialogClose, flow, progress, history}) => {

    const {flowsDispatch} = useStateValue();
    const [flowName, setFlowName] = useState(name || '');
    const handleChange = useCallback((event) => setFlowName(event.target.value), []);

    const [containerName, setContainerName] = useState('');

    const handleAdd = withErrorHandlingAndLoading(async () => {
        const response = await addFlow({name: flowName});
        flowsDispatch({
            type: actionTypes.ADD_FLOW,
            value: response.flow
        });
        setContainerName(response.flow.containerName);
    }, setProgress, handleAlert);

    const updateFlowName = withErrorHandlingAndLoading(async () => {
        await updateFlow(flow?.id, flowName);
        flowsDispatch({
            type: actionTypes.UPDATE_FLOW,
            flowId: flow?.id
        });
    }, setProgress, handleAlert);

    return <Dialog open={flowControl.add || flowControl.edit}>
        <DialogTitle id="form-dialog-title">
            {flowControl.add ? 'New Flow' : flowControl.edit ? 'Update Flow' : ""}
        </DialogTitle>

        <DialogContent dividers>

            <DialogContentText>Enter Flow Name :</DialogContentText>

            { progress ? <Loading size={12}/> : null }

            <TextField
                autoFocus
                value={flowName}
                margin="dense"
                label="Flow Name"
                fullWidth
                onChange={handleChange}
            />
            {containerName && flowControl.add && (
                <Alert severity="success">{`Flow ${flowName} created successfully`}</Alert>
            )}
        </DialogContent>

        <DialogActions>
            <Button onClick={() => handleDialogClose(flowControl.add ? 'add' : 'edit')} color="primary">
                Close
            </Button>
            {(containerName && flowControl.add) ? (
                <Button onClick={() => history.push(`nodered/${containerName}`)} color="primary">
                    View
                </Button>
            ) : (
                <Button onClick={flowControl.add ? handleAdd : updateFlowName} color="primary">
                    {flowControl.add ? 'Add' : 'Update'}
                </Button>
            )}
        </DialogActions>
    </Dialog>;
}