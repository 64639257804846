import React, { useEffect, useState } from 'react';
import { withErrorHandlingAndLoading } from "../../../../../constants/functions";
import {
    TextField,
    Grid,
    Typography,
    Button,
    makeStyles,
    MenuItem,
    FormControl,
    InputLabel,
    Chip,
    Select,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useStateValue } from "../../../../../StateContext";
import { actionTypes } from "../../../../../StateContext/types";
import {addProduct} from "../../../../../constants/stripe";
import {fetchUsers} from "../../../../../constants/users";

const useStyles = makeStyles((theme) => ({
    formContainer: {
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
    },
}));

const ProductForm = ({ handleClose, handleAlert }) => {
    const classes = useStyles();
    const { usersState, usersDispatch, productsDispatch } = useStateValue();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const setUsers = withErrorHandlingAndLoading(async () => {
            try {
                const result = await fetchUsers();
                usersDispatch({
                    type: actionTypes.SET_USERS,
                    value: result.users
                });
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        }, setLoading, handleAlert);

        setUsers();
    }, []);

    const formik = useFormik({
        initialValues: {
            name: '',
            unitAmount: '',
            taxCode: 'txcd_10000000',
            devices: '',
            messages: '',
            level: '',
            interval: 'month',
            currency: 'usd',
            users: [],
            planType: 'Mqtt',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Required'),
            unitAmount: Yup.number().required('Required'),
            taxCode: Yup.string().required('Required'),
            devices: Yup.string().required('Required'),
            messages: Yup.string().required('Required'),
            level: Yup.string().required('Required'),
            interval: Yup.string().required('Required'),
            currency: Yup.string().required('Required'),
            planType: Yup.string().required('Required'),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const product = {
                    name: values.name,
                    unitAmount: values.unitAmount,
                    taxCode: values.taxCode,
                    metadata: {
                        devices: values.devices,
                        messages: values.messages,
                        level: values.level
                    },
                    interval: values.interval,
                    currency: values.currency,
                    users: values.users,
                    planType: values.planType,
                };
                const response = await addProduct(product);
                await productsDispatch({
                    type: actionTypes.ADD_PRODUCT,
                    value: response,
                });
                resetForm();
                handleClose();
            } catch (error) {
                console.error('Error adding product:', error);
                // Handle error
            }
        },
    });

    return (
        <>
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error.message}</p>}
            {!loading && !error && (
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2} className={classes.formContainer}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Add New Product</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Product Name"
                                name="name"
                                variant="outlined"
                                required
                                fullWidth
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Unit Amount"
                                name="unitAmount"
                                variant="outlined"
                                required
                                fullWidth
                                type="number"
                                value={formik.values.unitAmount}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.unitAmount && Boolean(formik.errors.unitAmount)}
                                helperText={formik.touched.unitAmount && formik.errors.unitAmount}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Tax Code"
                                name="taxCode"
                                variant="outlined"
                                required
                                fullWidth
                                value={formik.values.taxCode}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.taxCode && Boolean(formik.errors.taxCode)}
                                helperText={formik.touched.taxCode && formik.errors.taxCode}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Devices"
                                name="devices"
                                variant="outlined"
                                required
                                fullWidth
                                value={formik.values.devices}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.devices && Boolean(formik.errors.devices)}
                                helperText={formik.touched.devices && formik.errors.devices}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Messages"
                                name="messages"
                                variant="outlined"
                                required
                                fullWidth
                                value={formik.values.messages}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.messages && Boolean(formik.errors.messages)}
                                helperText={formik.touched.messages && formik.errors.messages}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Level"
                                name="level"
                                variant="outlined"
                                required
                                fullWidth
                                value={formik.values.level}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.level && Boolean(formik.errors.level)}
                                helperText={formik.touched.level && formik.errors.level}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                select
                                label="Interval"
                                name="interval"
                                variant="outlined"
                                required
                                fullWidth
                                value={formik.values.interval}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.interval && Boolean(formik.errors.interval)}
                                helperText={formik.touched.interval && formik.errors.interval}
                            >
                                <MenuItem value="week">Weekly</MenuItem>
                                <MenuItem value="month">Monthly</MenuItem>
                                <MenuItem value="year">Yearly</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Currency"
                                name="currency"
                                variant="outlined"
                                required
                                fullWidth
                                value={formik.values.currency}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.currency && Boolean(formik.errors.currency)}
                                helperText={formik.touched.currency && formik.errors.currency}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="plan-type-label">Plan Type</InputLabel>
                                <Select
                                    labelId="plan-type-label"
                                    id="plan-type-select"
                                    name="planType"
                                    value={formik.values.planType}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    label="Plan Type"
                                    error={formik.touched.planType && Boolean(formik.errors.planType)}
                                >
                                    <MenuItem value="Mqtt">Mqtt alone</MenuItem>
                                    <MenuItem value="Kafka">Kafka alone</MenuItem>
                                    <MenuItem value="Mqtt_Kafka">Mqtt + Kafka</MenuItem>
                                </Select>
                                {formik.touched.planType && formik.errors.planType && (
                                    <Typography color="error" variant="caption">
                                        {formik.errors.planType}
                                    </Typography>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                multiple
                                name="users"
                                options={usersState}
                                getOptionLabel={(option) => option.name}
                                value={formik.values.users}
                                onChange={(event, value) => formik.setFieldValue('users', value)}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip key={index} label={option.name} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Users"
                                        placeholder="Select users"
                                        error={formik.touched.users && Boolean(formik.errors.users)}
                                        helperText={formik.touched.users && formik.errors.users}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid container justifyContent={"flex-end"}>
                            <Button type="submit" variant="contained" color="primary">Add Product</Button>
                        </Grid>
                    </Grid>
                </form>
            )}
        </>
    );
};

export default ProductForm;
