import React from 'react';
import {Route} from 'react-router-dom';
import PropTypes from 'prop-types';

const RouteWithLayout = props => {
    const {layout: Layout, component: Component, url, history, ...rest} = props;

    return (
        <Route
            {...rest}
            render={matchProps => (
                <Layout history={history} url={url}>
                    <Component {...matchProps} url={url}/>
                </Layout>
            )}
        />
    );
};

RouteWithLayout.propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string,
    url: PropTypes.string,
    isAdmin: PropTypes.bool,
    islogin: PropTypes.bool,
    history: PropTypes.any,

};

export default RouteWithLayout;
