import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {getToken, getUser, setUserSession, getRole} from '../../../../Utils/common';
import axios from "axios";
import {makeStyles} from '@material-ui/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {
    Card,
    CardContent,
    CardActions,
    Divider,
    Grid,
    Button,
    TextField
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {convertIntoDate} from "../../../../constants/functions";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
    root: {}
}));

const AccountDetails = props => {
    const {className, url, ...rest} = props;
    const classes = useStyles();

    const user = getUser();
    const [open, setOpen] = React.useState(false);

    const handleClick = () => { setOpen(true); };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') { return; }
        setOpen(false);
    };

    const handleSubmit = (values, { setSubmitting }) => {
        axios.put(`${url}/api/users/${user.id}`, values, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
            },
        })
            .then(() => {
                const updatedUser = { ...user, ...values };
                setUserSession(getToken(), updatedUser, getRole());
                handleClick();
            })
            .catch(error => {
                if (error.response.status === 401) alert("Unauthorized");
            })
            .finally(() => setSubmitting(false));
    };

    return (
        <Card sx={{height: '100%'}} {...rest} className={clsx(classes.root, className)} >
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    Info updated successfully
                </Alert>
            </Snackbar>

            <Formik
                initialValues={{
                    name: user.name || '',
                    username: user.username || '',
                    email: user.email || '',
                    phone: convertIntoDate(user.created) || '',
                }}
                validationSchema={Yup.object({
                    name: Yup.string().required('Name is required'),
                    username: Yup.string().required('Username is required'),
                    email: Yup.string().email('Invalid email address').required('Email is required'),
                })}
                onSubmit={handleSubmit}
            >
                {({ errors, touched, isSubmitting }) => (
                    <Form autoComplete="off" noValidate>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <Field
                                        name="name"
                                        as={TextField}
                                        label="Name"
                                        fullWidth
                                        variant="outlined"
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field
                                        name="username"
                                        as={TextField}
                                        label="Username"
                                        fullWidth
                                        variant="outlined"
                                        error={touched.username && Boolean(errors.username)}
                                        helperText={touched.username && errors.username}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field
                                        name="email"
                                        as={TextField}
                                        label="Email Address"
                                        fullWidth
                                        variant="outlined"
                                        error={touched.email && Boolean(errors.email)}
                                        helperText={touched.email && errors.email}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Field
                                        name="phone"
                                        as={TextField}
                                        label="Creation Date"
                                        fullWidth
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <Divider/>
                        <CardActions style={{float: "right"}}>
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                Update
                            </Button>
                        </CardActions>
                    </Form>
                )}
            </Formik>
        </Card>
    );
};

AccountDetails.propTypes = {
    className: PropTypes.string
};

export default AccountDetails;
