import React from 'react';
import { StateProvider } from '../../../StateContext';
import Header from "./Views/Header";
import Main from "./Views/Main"

const App = () =>
      <div style={{ flexGrow: 1 }}>
        <Header title="PAYMENT FORM" logoLink="/logo.png" />
          <Main />
      </div>
export default App;
