import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {Avatar, Card, CardActions, CardContent, CardHeader, Divider, Grid, TextField} from '@material-ui/core';

import {SearchInput} from '../../../../Components';
import Snackbar from "@material-ui/core/Snackbar";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import axios from "axios";


import MuiAlert from "@material-ui/lab/Alert";
import AccountTreeIcon from "@material-ui/icons/AccountTree";

import ReactJson from "react-json-view";
import CardActionArea from "@material-ui/core/CardActionArea";
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DynamicField from "./DynamicField";
import AddIcon from "@material-ui/icons/Add";
import {getToken} from "../../../../Utils/common";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const useStyles = makeStyles(theme => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    searchInput: {
        marginRight: theme.spacing(1)
    }
}));

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);
const Schemadetails = props => {
    const {className, history, setValues, url, values, ...rest} = props;
    const [schema, setSchemas] = useState([]);


    const getSchema = async (name) => {
        try {
            let res = await fetch(`${url}/getSchema`,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            await setSchemas(myjson);
        } catch (err) {
            alert('fetch data error ' + err)
        }
    }

    useEffect(() => {
        getSchema()
    }, []);
    const classes = useStyles();
    const routeChange = () => {
        let path = `apidetails`;
        history.push(path);
    }
    const handleChange = event => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(true)

    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);

    };
    const handlesetValues = (product) => {


        let data = product.listTopics;
        data.push(values.name)

        let val = []
        val.push(product.name)
        let da = product.description
        setValues({
            ...values,
            ["variables"]: da,
            ["listTopics"]: data,
            ["schema"]: val
        });

        console.log(values)
        handleClicknot()

    }
    const [ownerState, setOwnerState] = useState({
        owner: '',
        description: '',
        schema: '',
        topic: "",
        shards: 1,
        add: true
    });
    const handleOwnerChange = (e) => setOwnerState({
        ...ownerState,
        [e.target.name]: [e.target.value],
    });

    const field = {name: '', type: ''};
    const [state, setState] = useState([
        {...field},
    ]);

    const addField = () => {
        setState([...state, {...field}]);
    };

    const handleCatChange = (e) => {
        const updatedCats = [...state];
        updatedCats[e.target.dataset.idx][e.target.className] = e.target.value;
        setState(updatedCats);
        console.log(state)
    };


    const handleAdd = () => {

        axios.post(url + '/addSchema/', {
            schema: ownerState.schema[0],
            topic: [values.name],
            variables: state,
            shards: ownerState.shards,
            add: ownerState.add,
            elasticUsername: values.elasticUsername,
            elasticPassword: values.elasticPassword,
            connectionUrl: values.connectionUrl
        }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}}).then(response => {
            console.log(response)
            if (response) {
                getSchema();
                handleClose();
            }
        }).catch(error => {
            if (error.response.status === 401) console.log(" error ");
        });


    }


    const [opennot, setOpennot] = React.useState(false);

    const handleClicknot = () => {
        setOpennot(true);
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClosenot = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpennot(false);

    };
    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={3}>


                </Grid>
                <Grid item xs={3}>

                </Grid>
                <Grid item xs={3}>
                </Grid>
                <Grid item xs={3}>
                    <Snackbar open={opennot} autoHideDuration={3000} onClose={handleClosenot}>
                        <Alert onClose={handleClosenot} severity="success">
                            Schema Selected with success

                        </Alert>
                    </Snackbar>
                    <div className={classes.row}>
                        <span className={classes.spacer}/>

                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleClick}
                            style={{float: "right"}}
                        >
                            Add Template
                        </Button>

                    </div>
                </Grid>

                <Grid item xs={12}>
                    <Divider></Divider>
                </Grid>

                {schema.map(product => (
                    <Grid item xs={3}>


                        <Card
                            {...rest}
                            className={clsx(classes.root, className)}
                        >
                            <CardActionArea>
                                <CardContent>
                                    <Grid
                                        container
                                        justify="space-between"
                                    >
                                        <Grid item>
                                            <Typography
                                                className={classes.title}
                                                color="inherit"
                                                gutterBottom
                                                variant="body2"
                                            >

                                            </Typography>
                                            {product.name}
                                        </Grid>
                                        <Grid item>
                                            <Avatar className={classes.avatar}>
                                                <AccountTreeIcon className={classes.icon}/>
                                            </Avatar>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))
                }
            </Grid>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Add Schema
                </DialogTitle>
                <DialogContent dividers>
                    <form>

                        <Divider/>
                        <CardContent>
                            <label htmlFor={"schema"}>{"Template name "}</label>
                            <input
                                type="text"
                                name={"schema"}
                                style={{
                                    width: "80%",
                                    "background-color": "#f6f7ff",
                                    "color": "black",
                                    "padding": "14px 20px",
                                    "margin": "8px 0",
                                    "border": "1px solid #ccc",
                                    "cursor": "pointer"
                                }}
                                value={ownerState.schema}
                                onChange={handleOwnerChange}

                            />

                            <label htmlFor={"shards"}>{"number of shards"}</label>
                            <input
                                type="text"
                                name={"shards"}
                                style={{
                                    width: "75%",
                                    "background-color": "#f6f7ff",
                                    "color": "black",
                                    "padding": "14px 20px",
                                    "margin": "8px 0",
                                    "border": "1px solid #ccc",
                                    "border-radius": "4px",
                                    "cursor": "pointer"
                                }}
                                value={ownerState.shards}
                                onChange={handleOwnerChange}
                            />

                            <label htmlFor={"link"}>{"Connection Link"}</label>
                            <input
                                type="text"
                                name={"shards"}
                                style={{
                                    width: "75%",
                                    "background-color": "#f6f7ff",
                                    "color": "black",
                                    "padding": "14px 20px",
                                    "margin": "8px 0",
                                    "border": "1px solid #ccc",
                                    "border-radius": "4px",
                                    "cursor": "pointer"
                                }}
                                value={values.connectionUrl}
                                onChange={handleChange}
                            />
                            <label htmlFor={"username"}>{" username "}</label>
                            <input
                                type="text"
                                name={"elasticUsername"}
                                style={{
                                    width: "80%",
                                    "background-color": "#f6f7ff",
                                    "color": "black",
                                    "padding": "14px 20px",
                                    "margin": "8px 0",
                                    "border": "1px solid #ccc",
                                    "cursor": "pointer"
                                }}
                                value={values.elasticUsername}
                                onChange={handleChange}

                            />
                            <label htmlFor={"password"}>{" password "}</label>
                            <input
                                type="text"
                                name={"elasticPassword"}
                                style={{
                                    width: "80%",
                                    "background-color": "#f6f7ff",
                                    "color": "black",
                                    "padding": "14px 20px",
                                    "margin": "8px 0",
                                    "border": "1px solid #ccc",
                                    "cursor": "pointer"
                                }}
                                value={values.elasticPassword}
                                onChange={handleChange}

                            />


                            <Divider></Divider>
                            {
                                state.map((val, idx) => (
                                    <DynamicField
                                        key={`variable-${idx}`}
                                        idx={idx}
                                        state={state}
                                        handleCatChange={handleCatChange}
                                    />
                                ))
                            }
                            <IconButton aria-label="delete" color="primary">
                                <AddIcon onClick={addField}/>
                            </IconButton>
                        </CardContent>

                    </form>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleAdd} color="primary">
                        Save changes
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};

Schemadetails.propTypes = {
    className: PropTypes.string
};

export default Schemadetails;
