import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Grid,
    Paper,
    Typography,
} from '@material-ui/core';
import {FlowForm} from "../../../Flows/FlowForm";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        borderRadius: 8,
    },
    connectorPaper: {
        padding: theme.spacing(2),
        margin: theme.spacing(2, 0),
        border: '1px solid #ccc',
        borderRadius: 8,
        backgroundColor: '#f9f9f9',
        textAlign: 'center',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    },
    title: {
        marginBottom: theme.spacing(2),
        color: theme.palette.text.primary,
    },
}));

const Connectors = ({ values, setValues, handleAlert, setLoading, loading }) => {
    const classes = useStyles();
    const [dialogState, setDialogState] = useState({ add: false, type: '' });

    const handleAddFlow = (type) => {
        setDialogState({ add: true, type });
    };

    const handleDialogClose = () => {
        setDialogState({ add: false, type: '' });
    };

    return (
        <Grid justifyContent='center' container spacing={2}>
            <Grid item xs={12}>
                <Typography align={"center"} variant={'h6'}>
                    Flow Information
                </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                <Paper className={classes.connectorPaper} onClick={() => handleAddFlow()}>
                    <Typography variant="subtitle1">Add New Flow</Typography>
                </Paper>
            </Grid>

            <FlowForm {...{flowControl:dialogState , setLoading, handleAlert, handleDialogClose , progress:loading }}/>

        </Grid>
    );
};

Connectors.propTypes = {
    values: PropTypes.object.isRequired,
    setValues: PropTypes.func.isRequired,
    handleAlert: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
};

export default Connectors;
