import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  makeStyles,
} from "@material-ui/core";
import { Add, Edit, Delete } from "@material-ui/icons";
import UserForm from "./UserForm";
import { withErrorHandlingAndLoading } from "../../constants/functions";
import MessageAlert from "../../Components/Alert";
import {deleteUser, fetchUsers} from "../../constants/users";
import { useStateValue } from "../../StateContext";
import { actionTypes } from "../../StateContext/types";
import Loading from "../../Components/Loading/Loading";
import { ActionsToolbar } from "../../Components";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    padding: 10,
    overflow: "auto",
    maxHeight: "calc(100vh - 220px)",
    "& .MuiTableCell-root": {
      padding: "10px", // Adjust the padding as needed
    },
    "& .MuiPaper-elevation4": {
      boxShadow: "0 0 0 0", // Adjust the shadow as needed
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90vw", // Set the maxWidth to device width on small screens
      overflowX: "auto", // Enable horizontal scrolling on small screens
    },
  },
}));

export default function UserTable({ path }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { usersState, usersDispatch } = useStateValue();
  const [user, setUser] = useState(null);
  const [openForm, setOpenForm] = useState('');
  const [alert, setAlert] = useState({ open: false, message: "", severity: "" });
  const [searchQuery, setSearchQuery] = useState('');

  const handleOpenForm = (type) => setOpenForm(type);
  const handleCloseForm = () => setOpenForm('');

  const handleAlertOpen = (open, message, severity) => setAlert({ open, message, severity });
  const handleAlertClose = () => setAlert((prevState) => ({ ...prevState, open: false }));

  useEffect(() => {
    const listUsers = withErrorHandlingAndLoading(async () => {
      const resp = await fetchUsers();
      usersDispatch({ type: actionTypes.SET_USERS, value: resp });
    }, setLoading, handleAlertOpen);

    listUsers();
  }, [usersDispatch]);

  const handleActionClick = (user, type) => {
    if (type !== "ADD") setUser(user);
    handleOpenForm(type);
  };


  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };


  const filteredData = usersState.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
      <>
        <MessageAlert
            open={alert.open}
            message={alert.message}
            onClose={handleAlertClose}
            severity={alert.severity}
        />
        {loading ? (
            <Loading />
        ) : (
            <>
              <ActionsToolbar title={"User"} handleSearch={handleSearchChange} openForm={handleOpenForm} />
              <TableContainer className={classes.tableContainer} component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Username</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Organization</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData.map((user) => (
                        <TableRow key={user.id}>
                          <TableCell>{user.name}</TableCell>
                          <TableCell>{user.username}</TableCell>
                          <TableCell>{user.email}</TableCell>
                          <TableCell>{user.organization}</TableCell>
                          <TableCell>
                            <IconButton onClick={() => handleActionClick(user, "EDIT")} aria-label="edit">
                              <Edit />
                            </IconButton>
                            <IconButton onClick={() =>handleActionClick(user, "DELETE")} aria-label="delete">
                              <Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <UserForm
                  open={openForm}
                  onClose={handleCloseForm}
                  handleAlertOpen={handleAlertOpen}
                  initialValues={openForm === "EDIT" || openForm === 'DELETE'  ? user : { name: "", username: "", email: "", organization: "", password: "" }}
              />
            </>
        )}
      </>
  );
}
