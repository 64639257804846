import React, {useState, useEffect, useCallback} from 'react';
import {
  Container,
  Typography,
  Grid,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddProductForm from './Payment/Views/Forms/ProductForm'; // Import the new AddProductForm component
import MetadataForm from "./Payment/Views/Forms/MetadataForm";
import {
  getUserFromLocalStorage,
  isAdmin, withErrorHandlingAndLoading
} from '../../constants/functions';
import { useStateValue } from "../../StateContext";
import {actionTypes} from "../../StateContext/types";

import Plan from './Plan';
import {clsx} from "clsx";
import MessageAlert from "../../Components/Alert";
import LinearProgress from "@material-ui/core/LinearProgress";
import Loading from "../../Components/Loading/Loading";
import {fetchCustomerInfo, fetchProducts, updateProduct} from "../../constants/stripe";


const useStyles = makeStyles((theme) => ({
  container: {
    width: '90%',
    [theme.breakpoints.up('md')]: {
      maxWidth: 'none',
    },
  },
  drawerOpen: {
    width: '100%',
  }
}));

export default function Plans() {
  const classes = useStyles();
  const { drawerState, metadataState : metadataValues, metadataDispatch, productsState: products, productsDispatch,} = useStateValue();
  const [customerId, setCustomerId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [openAddProductDialog, setOpenAddProductDialog] = useState(false);
  const [openMetadataFormDialog, setOpenMetadataFormDialog] = useState(false);

  const [alert, setAlert] = useState({open: false, message: '', severity: 'info'});
  const handleAlert = useCallback((open, message, severity) => {
    setAlert({open, message, severity});
    }, []);
  const handleClose = useCallback(() => setAlert(prevState => ({ ...prevState, open: false })), []);

  const handleCloseAddProductDialog = () => {
    setOpenAddProductDialog(false);
  };

  const handleCloseMetadataFormDialog = () => {
    setOpenMetadataFormDialog(false);
  };

  const fetchCustomerInfoData = withErrorHandlingAndLoading(async () => {
      if (customerId) {
        const data = await fetchCustomerInfo(customerId);
        setCurrentPlan(data);
      }
  },setLoading, handleAlert);

  useEffect(() => {
    const user = getUserFromLocalStorage();
    if (user && user.CusId) {
      setCustomerId(user.CusId);
    }
  }, []);

  useEffect(() => {
    const fetchCustomerInfo = withErrorHandlingAndLoading(async () => {
      await fetchCustomerInfoData();
    },setLoading , handleAlert)

    const fetchProductsData = withErrorHandlingAndLoading(async () => {
        const data = await fetchProducts();
        productsDispatch({ type: actionTypes.SET_PRODUCTS, value: data });
    },setLoading , handleAlert);

    fetchProductsData();
    fetchCustomerInfo();
  }, [customerId]);

  console.log(loading)


  const handleUpdateMetadata = withErrorHandlingAndLoading(async () => {

      const result = await updateProduct( metadataValues );
      productsDispatch({
        type: 'UPDATE_PRODUCT',
        productId : metadataValues.productId,
        value: result.updated_product
      });
      handleCloseMetadataFormDialog();
  },setLoading,handleAlert);

  const handleMetadaDataFormOpen = (metadata) =>{
    metadataDispatch({
      type: 'SET_METADATA_VALUES',
      value: metadata
    });
    setOpenMetadataFormDialog(true);
  }

  const currentLevel = products && currentPlan && (products.find(product => product.priceid === currentPlan.priceId))?.metadata.level;

  const sortedProducts = products.sort((a, b) => a.price - b.price);


  return (
      <>
        <MessageAlert
            open={alert.open}
            message={alert.message}
            onClose={handleClose}
            severity={alert.severity}
        />
        <div style={{ padding: 20 }}>

        <Grid container justifyContent='space-between' style={{margin : 5}}>

          <div>
            <Typography variant="h4">Pricing</Typography>
            <span variant="subtitle1" gutterBottom> Credit balance: <b>{currentPlan?.balance}$</b></span>
          </div>

          { isAdmin() &&
            <Button align="center" variant="contained" color="primary" onClick={() => setOpenAddProductDialog(true)}>
               Add Product
            </Button>
          }
        </Grid>


          {   loading ?  <Loading size={24}/>  :
            <Grid container justifyContent='center' spacing={2}>
                <Grid item xs={12}> <Divider /></Grid>
                {
                  sortedProducts.map(product => (
                      <Grid item key={product.id} xs={12} sm={6} md={4} lg={3}>
                        <Plan
                            isActive = {product.active}
                            productId={product.id}
                            name={product.name}
                            price={product.price}
                            features={product.metadata}
                            priceid={product.priceid}
                            setCurrentPlan={setCurrentPlan}
                            currentPlan={{ ...currentPlan, level: currentLevel }}
                            customerId={customerId}
                            fetchCustomerInfoData={fetchCustomerInfoData}
                            handleMetadaDataFormOpen={handleMetadaDataFormOpen}
                        />
                      </Grid>
                    ))
                }
            </Grid>
          }

          <Dialog fullWidth open={openAddProductDialog} onClose={handleCloseAddProductDialog}>
            <DialogContent>
              <AddProductForm onClose={handleCloseAddProductDialog} handleAlert={handleAlert} />
            </DialogContent>
          </Dialog>
          <Dialog open={openMetadataFormDialog} onClose={handleCloseMetadataFormDialog}>
            <DialogTitle>Update Product Metadata</DialogTitle>
            <DialogContent>
              <MetadataForm />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseMetadataFormDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleUpdateMetadata} color="primary">
                Update
              </Button>
            </DialogActions>
          </Dialog>

        </div>
      </>
  );
}
