import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import LinkIcon from '@material-ui/icons/Link';
import {
    Card,
    CardContent,
    CardActions,
    Typography,
    Grid,
    Divider, TableCell
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import GetAppIcon from '@material-ui/icons/GetApp';
import {StatusBullet} from "../../../../Components";
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import CardActionArea from "@material-ui/core/CardActionArea";

const useStyles = makeStyles(theme => ({
    root: {},
    imageContainer: {
        height: 64,
        width: 64,
        margin: '0 auto',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    image: {
        width: '100%'
    },
    statsItem: {
        display: 'flex',
        alignItems: 'center'
    },
    statsIcon: {
        color: theme.palette.icon,
        marginRight: theme.spacing(1)
    }
}));


const ProductCard = props => {
    const {className, product, url, setValues, history, ...rest} = props;

    const classes = useStyles();
    const statusColors = {
        delivered: 'success',
        pending: 'info',
        refunded: 'danger'
    }
    const [openDelete, setOpenDelete] = useState(false);
    const handleOpenDelete = (id_model) => {
        setOpenDelete(true);
    }
    const handleCloseDelete = () => {
        setOpenDelete(false);
    }

    const handleUpdate = () => {

        history.push({
            pathname: '/apiupdate/' + product.id,

            state: {detail: product.id}
        })


    }
    const fetchData = async () => {
        try {
            let res = await fetch(`${url}/allApi/`,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json'}
                });
            let myjson = await res.json();
            await setValues(myjson);
        } catch (err) {
            alert('fetch data error ' + err)
        }
    }
    const handleDeleteContinue = async () => {
        try {
            let res = await fetch(`${url}/deleteApi/` + product.id, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'}
            })
            if (res) {
                handleCloseDelete();
                fetchData()
            }
        } catch (error) {
            console.error('Error error:', error)
        }
    }
    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardActionArea>
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe" className={classes.avatar}>
                            A
                        </Avatar>
                    }
                    action={
                        <IconButton aria-label="settings" onClick={handleOpenDelete}>
                            <DeleteIcon/>
                        </IconButton>
                    }
                    title={product.modelName}
                    subheader={product.topic}
                />
                <CardContent onClick={handleUpdate}>
                    <div className={classes.imageContainer}>


                        <img
                            alt="Product"
                            className={classes.image}
                            src={"/images/products/product_2.png"}
                        />
                    </div>
                    <Typography
                        align="center"
                        gutterBottom
                        variant="h4"
                    >
                        {product.name}
                    </Typography>
                    <Typography
                        align="center"
                        variant="body1"
                    >

                    </Typography>
                </CardContent>
            </CardActionArea>
            <Divider/>
            <CardActions>
                <Grid
                    container
                    justify="space-between"
                >
                    <Grid
                        className={classes.statsItem}
                        item
                    >
                        <LinkIcon className={classes.statsIcon}/>
                        <Typography
                            display="inline"
                            variant="body2"
                        >
                            {product.host}
                        </Typography>
                    </Grid>
                    <Grid
                        className={classes.statsItem}
                        item
                    >
                        <div className={classes.statusContainer}>
                            <StatusBullet
                                className={classes.status}
                                color={'success'}
                                size="sm"
                            />

                        </div>
                        <Typography
                            display="inline"
                            variant="body2"
                        >
                        </Typography>


                    </Grid>
                </Grid>
            </CardActions>
            <Dialog open={openDelete} onClose={handleCloseDelete}>
                <DialogTitle disableTypography={false}>Confirmation</DialogTitle>
                <DialogContent dividers>
                    Are you sure you want to delete this api ?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        handleDeleteContinue()
                    }} color='primary' size='small'> yes </Button>
                    <Button onClick={handleCloseDelete} color='primary' size='small'> No </Button>
                </DialogActions>

            </Dialog>


        </Card>
    );
};

ProductCard.propTypes = {
    className: PropTypes.string,
    product: PropTypes.object.isRequired
};

export default ProductCard;
