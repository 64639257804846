import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid } from '@material-ui/core';
import { SearchInput } from '../index';

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(2),
    },
    searchInput: {
        marginRight: theme.spacing(1),
        width: '100%',
        maxWidth: 300,
    },
    addButton: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1),
            width: '100%',
        },
    },
}));

const ConnectorsToolbar = ({ title, handleSearch , openForm }) => {

    const classes = useStyles();

    const handleAdd = () => { openForm('ADD') };

    return (
        <div className={classes.root}>
            <Grid spacing={2} container justifyContent={"space-between"}>
                <Grid item xs={12} sm={6}>
                    <SearchInput
                        size={'small'}
                        className={classes.searchInput}
                        placeholder={`Search ${title}`}
                        onChange={handleSearch}
                    />
                </Grid>
                <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        className={classes.addButton}
                        color="primary"
                        variant="contained"
                        onClick={handleAdd}
                    >
                        {`Add ${title}`}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

ConnectorsToolbar.propTypes = {
    handleSearch: PropTypes.func.isRequired,
    openForm: PropTypes.func.isRequired,
};

export default ConnectorsToolbar;
