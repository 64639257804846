import React, { useEffect, useState } from 'react';
import {
    fetchKafkaTopics,
    fetchLatestMessages,
    fetchTopicMessages,
    withErrorHandlingAndLoading
} from "../../constants/functions";
import {useStateValue} from "../../StateContext";
import {actionTypes} from "../../StateContext/types";
import LineGraph from './LineGraph';
import TopicsListTable from './TopicsListTable';
import MessageAlert from "../../Components/Alert";
import {SmallCard} from "../../Components/SmallCard";
import Loading from "../../Components/Loading/Loading";
import { Grid,Paper, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import TopicForm from "./TopicForm";
import {MessageCard} from "../../Components/MessageCard";
import Divider from "@material-ui/core/Divider";


const Topics = () => {

    const {kafkaTopicsState, kafkaTopicsDispatch} = useStateValue();
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState({});
    const [loading,setLoading] = useState(false);

    const [streaming,setStreaming] = useState(false);
    const [selectedTopicsIds, setSelectedTopicsIds] = useState([]);

    const [dialogType, setDialogType] = useState(null);
    const openDialog = (type) => { setDialogType(type); };
    const closeDialog = () => { setDialogType(null); };

    const [alert, setAlert] = useState({
        open : false , message : "" , severity : "info"
    });
    const handleAlert = (open, message, severity) =>{
        setAlert({
            open : open ,
            message :message ,
            severity: severity
        })
    }
    const handleClose = () => {setAlert(prevState => ({ ...prevState, open: false }));};

    const listKafkaTopics = withErrorHandlingAndLoading(async () => {
        const response  = await fetchKafkaTopics();
        const resp = await fetchLatestMessages();
        setMessages(resp);
        await kafkaTopicsDispatch({
            type : actionTypes.SET_TOPICS,
            value : response.topics
        });
    }, setLoading, handleAlert);

    const listMessages = withErrorHandlingAndLoading(async (topicId) => {
        const response = await fetchTopicMessages(topicId);
        setMessages(response);
    }, setLoading , handleAlert);

    const handleOpenClick = (message) => {
        setMessage(message)
        openDialog(1)
    };

    useEffect(() => { listKafkaTopics() }, []);

    useEffect(() => {
        selectedTopicsIds.length === 0 && setStreaming(false) ;
    }, [selectedTopicsIds]);

    return (
        <>
            <MessageAlert open={alert.open} message={alert.message} onClose={handleClose} severity={alert.severity}/>

            <Grid container style={{ height: 'calc(100vh - 160px)' }} spacing={2}>
                <Grid item xs={12} md={6} style={{ height: '100%' }}>
                    <TopicsListTable
                        streaming={streaming}
                        setStreaming={setStreaming}
                        kafkaTopicsState={kafkaTopicsState}
                        selectedTopicsIds={selectedTopicsIds}
                        setSelectedTopicsIds={setSelectedTopicsIds}
                        handleAlert={handleAlert}
                        listMessages={listMessages}
                        openDialog={openDialog}
                    />
                </Grid>

                <Grid item direction="column" xs={12} md={6} style={{ height: '100%' }}>
                    { streaming &&
                        <Grid item style={{ height: 'calc(50% - 5px)', marginBottom : '10px'  }}>
                            <LineGraph selectedTopicsIds={selectedTopicsIds} streaming={streaming} />
                        </Grid>
                    }

                <Grid item style={{ height: streaming ? 'calc(50% - 5px)' : '100%'}}>
                    <Paper style={{ height:  '100%', padding: 20, overflow: 'auto' }}>
                        <Typography style={{marginBottom:'10px'}} variant={"h6"}> Latest Messages  </Typography>
                        <Grid container spacing={2}>

                            <Grid item xs={12}><Divider/></Grid>

                            {loading ? (<Grid item ><Loading /></Grid>) : (
                                messages.length === 0 ? (
                                    <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                                        <Typography>No messages available</Typography>
                                    </Grid>
                                ) : (
                                    messages.map((message, index) => (
                                        <Grid item lg={4} md={6} sm={6} xs={12} key={index}>
                                            <SmallCard
                                                name={message.topic_name}
                                                timestamp={(new Date(message.creationDate)).toLocaleString()}
                                                message={message}
                                                onOpen={() => handleOpenClick(message)}
                                            />
                                        </Grid>
                                    ))
                                )
                            )}
                        </Grid>
                    </Paper>
                </Grid>
                </Grid>
            </Grid>


            <TopicForm
                dialogType={dialogType}
                kafkaTopicsDispatch={kafkaTopicsDispatch}
                handleAlert={handleAlert}
                closeDialog={ closeDialog}
            />


            <Dialog open={dialogType === 1} onClose={closeDialog}>
                <DialogTitle disableTypography={false}>{message?.topic_name}</DialogTitle>
                <DialogContent dividers>
                    <MessageCard
                        name={message?.topic_name}
                        message={message}
                        handleAlert={handleAlert}
                    />
                </DialogContent>
            </Dialog>

        </>
    );
};

export default Topics;
