import React, {useCallback, useState} from "react";
import {
  setNotifications,
  setUserSession,
} from "../../Utils/common";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Link as RouterLink } from "react-router-dom";
import {withErrorHandlingAndLoading} from "../../constants/functions";
import MessageAlert from "../../Components/Alert";
import Loading from "../../Components/Loading/Loading";
import {login} from "../../constants/auth";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: 'info'
  });
  const handleAlert = useCallback((open, message, severity) => {
    setAlert({
      open,
      message,
      severity
    });
  }, []);
  const handleClose = useCallback(() => setAlert(prevState => ({ ...prevState, open: false })), []);
  const username = useFormInput("");
  const password = useFormInput("");
  const classes = useStyles();

  // handle button click of login form
  const handleLogin = withErrorHandlingAndLoading(async () => {
    const response = await login({identifier : username.value, password: password.value});
    setUserSession(response.token, response.user, {});
    setNotifications({});
    window.location.reload();
  },setLoading, handleAlert);

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") await handleLogin();
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <MessageAlert
          open={alert.open}
          message={alert.message}
          onClose={handleClose}
          severity={alert.severity}
      />

      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          <img
            src={"signin.png"}
            alt={"BeeMetrix"}
            width={"250px"}
            height={"100px"}
          />{" "}
        </Typography>

        <div className={classes.form}>
          <div>
            <TextField
              type="text"
              {...username}
              autoComplete="username"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username/Email"
              name="username"
              onKeyDown={handleKeyDown}
              autoFocus
            />
          </div>

          <div>
            <TextField
              type="password"
              {...password}
              autoComplete="new-password"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              onKeyDown={handleKeyDown}
              id="password"
              label="Password"
              name="password"
              autoFocus
            />
          </div>

          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Link component={RouterLink} to="/signup" variant="h6">
            Sign up
          </Link>

              <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  value={loading ? "Loading..." : "Login"}
                  onClick={handleLogin}
                  disabled={loading}
                >
                  Sign In
                </Button>
                { loading && <Loading/>}
        </div>
      </div>
    </Container>
  );
};

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = (e) => setValue(e.target.value);
  return { value, onChange: handleChange };
};

export default SignIn;
