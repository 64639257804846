import React, { useEffect, useState } from 'react';
import { Grid, ListItem, ListItemAvatar, ListItemText, Avatar, CircularProgress, Button } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons'; // Import the icon you want to use
import { useHistory } from 'react-router-dom';

import visa from '../../../../../public/visa.jpeg';
import mastercard from '../../../../../public/mastercard.png';
import amex from '../../../../../public/americanexpress.png';
import {methodsGet, performPayment} from "../../../../../constants/stripe";

const icons = { visa, mastercard, amex };

export default function Methods({ onAdd, user , priceId}) {

    const [methods, setMethods] = useState(null);
    const [currnetMethod, setCurrentMethod] = useState(null);
    const [loading, setLoading] = useState(false);
    const [defaultpm, setDefaultpm] = useState(null);
    const history = useHistory();

    useEffect(() => {

        const retriveMethods = async () => {
            try {
                setLoading(true);
                const data = await methodsGet(user.CusId);
                setMethods(data.methods);
                setDefaultpm(data.default);
            } catch (err) {
                console.log(err);
            }
            finally {
                setLoading(false);
            }
        }

        retriveMethods();

    }, [user.CusId]);

    const getMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);
        return date.toLocaleString('en-US', { month: 'short' });
    };

    const handlePayment = async () => {
        try {
            setLoading(true)
            await performPayment( user.CusId , currnetMethod , priceId);
            await history.push(`/plan`);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    };

    return (
        <Grid container direction="column" spacing={1}>
            <Grid item>
                <ListItem button onClick={onAdd}>
                    <ListItemAvatar>
                        <Avatar>
                            <AddCircle />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Add payment method" />
                </ListItem>
            </Grid>

            <Grid item container justifyContent="center" alignItems="center">
                {loading && <CircularProgress size={24} />}
            </Grid>

            {methods?.map((method) => (
                <Grid item key={method.id}>
                    <ListItem
                        className={"flex items-center w-full h-10 py-1 px-2 shadow-lg m-2 justify-between rounded"}
                        button
                        onClick={() => setCurrentMethod(method.id)}
                        disabled={method.id === currnetMethod}
                    >
                        <ListItemAvatar>
                            <Avatar alt={method.card?.brand} src={icons[method.card?.brand]} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={`......${method.card.last4}`}
                            secondary={`Expires ${getMonthName(method.card.exp_month)} ${method.card.exp_year}`}
                        />
                        <div className='rounded bg-main text-white text-xs h-fit w-10'>{(defaultpm === method.id) ? "default" : ""}</div>
                    </ListItem>
                </Grid>
            ))}


          { priceId &&   <Button
                onClick={handlePayment}
                disabled = {!currnetMethod}
                variant="contained"
                color="primary"
                style={{ alignSelf: 'flex-end', margin: '10px' }} // Align button to the end of the container
            >
                Perform Payment
            </Button>
          }
        </Grid>
    );
}
