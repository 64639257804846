import React from 'react';
import {Grid, TextField, Typography} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useStateValue} from "../../../../../StateContext";
import {actionTypes} from "../../../../../StateContext/types";
import {countries} from "../../../../../constants/countries";

const ContactForm = () => {

    const { formValuesState, formValuesDispatch }= useStateValue();

    return <>
        <Grid item xs={12}>
            <Typography variant="h6">Contact information</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextField
                label="First Name"
                name="firstname"
                variant="outlined"
                required
                fullWidth
                value={formValuesState.firstname}
                onChange={e =>
                    formValuesDispatch({
                        type: actionTypes.EDIT_FORM_VALUE,
                        key: "firstname",
                        value: e.target.value
                    })
                }
            />
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextField
                label="Last Name"
                name="lastname"
                variant="outlined"
                required
                fullWidth
                value={formValuesState.lastname}
                onChange={e =>
                    formValuesDispatch({
                        type: actionTypes.EDIT_FORM_VALUE,
                        key: "lastname",
                        value: e.target.value
                    })
                }
            />
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextField
                label="Email Address"
                name="email"
                variant="outlined"
                required
                fullWidth
                value={formValuesState.email}
                onChange={e =>
                    formValuesDispatch({
                        type: actionTypes.EDIT_FORM_VALUE,
                        key: "email",
                        value: e.target.value
                    })
                }
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
                label="Street Address 1"
                name="address1"
                variant="outlined"
                required
                fullWidth
                value={formValuesState.line1}
                onChange={e =>
                    formValuesDispatch({
                        type: actionTypes.EDIT_FORM_VALUE,
                        key: "line1",
                        value: e.target.value
                    })
                }
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField
                label="Street Address 2 (optional)"
                name="line2"
                variant="outlined"
                fullWidth
                value={formValuesState.line2}
                onChange={e =>
                    formValuesDispatch({
                        type: actionTypes.EDIT_FORM_VALUE,
                        key: "line2",
                        value: e.target.value
                    })
                }
            />
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextField
                label="Postal Code"
                name="postal_code"
                variant="outlined"
                required
                fullWidth
                value={formValuesState.postal_code}
                onChange={e =>
                    formValuesDispatch({
                        type: actionTypes.EDIT_FORM_VALUE,
                        key: "postal_code",
                        value: e.target.value
                    })
                }
            />
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextField
                label="City"
                name="city"
                variant="outlined"
                required
                fullWidth
                value={formValuesState.city}
                onChange={e =>
                    formValuesDispatch({
                        type: actionTypes.EDIT_FORM_VALUE,
                        key: "city",
                        value: e.target.value
                    })
                }
            />
        </Grid>
        <Grid item xs={12} sm={4}>
            <Autocomplete
                options={countries}
                getOptionLabel={option => option.name}
                renderInput={params =>
                    <TextField
                        label="Country"
                        name="country"
                        variant="outlined"
                        required
                        fullWidth
                        {...params}
                    />
                }
                value={formValuesState.country}
                onChange={(event, value) => {
                    formValuesDispatch({
                        type: actionTypes.EDIT_FORM_VALUE,
                        key: "country",
                        value: value
                    })
                }}
            />
        </Grid>
    </>
}

export default ContactForm;


