import React, {useEffect, useState} from 'react';

import Tabs from "./Tabs";
import MessageAlert from "../../Components/Alert";

const DbaseManager = () => {

    const [alert, setAlert] = useState({open: false, message: "", severity: ""});
    const handleAlertOpen = (open, message, severity) => {setAlert({ open, message, severity });};
    const handleAlertClose = () => { setAlert(prevState => ({ ...prevState, open: false })); };

    return (  <>
            <MessageAlert open={alert.open} message={alert.message} onClose={handleAlertClose} severity={alert.severity}/>
            <Tabs handleAlert={handleAlertOpen}  />
        </>
    );
};

export default DbaseManager;
