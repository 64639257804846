import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Box, Tab, Tabs } from '@material-ui/core';
import { AccountProfile, AccountDetails, BillingAddress , Invoices } from './components';
import PropTypes from 'prop-types';
import PersonIcon from '@material-ui/icons/Person';
import DescriptionIcon from '@material-ui/icons/Description';
import { listInvoices } from "../../constants/stripe";
import {getUserFromLocalStorage, withErrorHandlingAndLoading} from "../../constants/functions";
import MessageAlert from "../../Components/Alert";
import Main from "../Plan/Payment/Views/Main";
import Loading from "../../Components/Loading/Loading";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
    },
    container: {
        borderRadius: theme.shape.borderRadius,
    },
    item: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const Account = ({ url }) => {
    const classes = useStyles();
    const [invoices, setInvoices] = useState([]);
    const [user, setUer] = useState({});
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(0);
    const [billingValue, setBillingValue] = useState(0);
    const [alert, setAlert] = useState({ open: false, message: '', severity: 'info' });

    const handleAlert = useCallback((open, message, severity) => {
        setAlert({ open, message, severity });
    }, []);

    const handleClose = useCallback(() => setAlert((prevState) => ({ ...prevState, open: false })), []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleBillingChange = (event, newValue) => {
        setBillingValue(newValue);
    };

    useEffect(() => {

        const localUser = getUserFromLocalStorage();
        setUer(localUser);

        const showInvoices = withErrorHandlingAndLoading(async () => {
            const resp = await listInvoices(localUser.CusId);
            setInvoices(resp);
        },setLoading, handleAlert);

        showInvoices();

    }, []);

    return (
        <div className={classes.root}>
            <Box sx={{ bgcolor: 'background.paper' }}>
                <MessageAlert
                    open={alert.open}
                    message={alert.message}
                    onClose={handleClose}
                    severity={alert.severity}
                />

                {loading && <Loading/>}

                <Tabs
                    style={{ marginRight: 20 }}
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    sx={{ borderRight: 1 }}
                >
                    <Tab icon={<PersonIcon />} label="PROFILE" {...a11yProps(0)} />
                    { user?.CusId && <Tab icon={<DescriptionIcon />} label="BILLING" {...a11yProps(1)} />}
                </Tabs>

                <TabPanel value={value} index={0}>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12} md={4} className={classes.item}>
                            <AccountProfile url={url} />
                        </Grid>
                        <Grid item xs={12} md={8} className={classes.item}>
                            <AccountDetails url={url} />
                        </Grid>
                        <Grid item lg={12} md={12} xl={8} xs={12} className={classes.item}>
                            <BillingAddress handleAlert={handleAlert} />
                        </Grid>
                    </Grid>
                </TabPanel>

                <TabPanel value={value} index={1}>
                    <Tabs
                        style={{ marginRight: 20 }}
                        variant="scrollable"
                        value={billingValue}
                        onChange={handleBillingChange}
                        sx={{ borderRight: 1 }}
                    >
                        <Tab label="Billing History" {...a11yProps(2)} />
                        <Tab label="Payment Methods" {...a11yProps(3)} />
                    </Tabs>

                    <TabPanel value={billingValue} index={0}>
                        <Invoices invoices={invoices} />
                    </TabPanel>
                    <TabPanel value={billingValue} index={1}>
                        <Main />
                    </TabPanel>
                </TabPanel>
            </Box>
        </div>
    );
};

export default Account;
