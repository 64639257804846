import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button, colors } from '@material-ui/core';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

import { useLocation, useHistory } from 'react-router-dom';



const useStyles = makeStyles(theme => ({
    root: {},
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
        textDecoration: 'none',
    },
    button: {
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium
    },
    icon: {
        color: theme.palette.icon,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1)
    },
    active: {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        '& $icon': {
            color: theme.palette.primary.main
        }
    }
}));



const SidebarNav = props => {
    const { pages, className , ...rest } = props;
    const classes = useStyles();
    const location = useLocation();
    const [open, setOpen] = React.useState(false);
    const [link, setLink] = React.useState("");

    const history = useHistory();

    const handleClick = (href) => {
        if (location.pathname === "/steps") {
            setLink(href);
            setOpen(true);
        } else {
            history.push(href);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleGo = () => {
        history.push(link);
        setOpen(false);
    };

    return (
        <List {...rest}
            className={clsx(classes.root, className)}
        >
            {pages.map(page => (
                <ListItem
                    className={clsx(classes.item, {
                        [classes.active]: location.pathname === page.href
                    })}
                    disableGutters
                    key={page.title}
                >
                    <Button
                        className={classes.button}
                        onClick={() => handleClick(page.href)}
                    >
                        <div className={classes.icon}>{page.icon}</div>
                        {page.title}
                    </Button>
                </ListItem>
            ))}

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent dividers>
                    Are you sure you want to exit this process?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleGo} color="primary" size="small">Yes</Button>
                    <Button onClick={handleClose} color="primary" size="small">No</Button>
                </DialogActions>
            </Dialog>
        </List>
    );
};

SidebarNav.propTypes = {
    className: PropTypes.string,
    pages: PropTypes.array.isRequired,
    history: PropTypes.any
};

export default SidebarNav;
