import React, {useState} from 'react';
import {
    Paper,
    FormGroup,
    Divider,
    FormControlLabel,
    Toolbar,
    Button,
    TextField,
    MenuItem,
    ListItem,
    ListItemText,
    Checkbox,
    Hidden,
    DialogContent,
    DialogActions,
    Dialog,
    DialogTitle,
    IconButton,
    Grid,
    CardContent, colors
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {ExcelRenderer} from 'react-excel-renderer';
// import ExpandLess from '@material-ui/icons/ExpandLess';
// import ExpandMore from '@material-ui/icons/ExpandMore';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {makeStyles} from '@material-ui/core/styles';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import Card from "@material-ui/core/Card";
import clsx from "clsx";
import PropTypes from 'prop-types';
import MUIDataTable from "mui-datatables";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {DropzoneArea} from 'material-ui-dropzone' ;
import axios from "axios";
import MaterialTable from 'material-table';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({

    dropzoneClass: {
        borderRadius: 0,
        border: '2px dashed white',
        padding: '30px 0px',
        height: 150,
        background: "#c2c5c0",
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        width: '100%',
        overflow: 'auto',
        borderColor: '#FFFAFA',
        marginBottom: 10,
        textAlign: 'center',
    }
    ,
    dropzone: {
        borderRadius: 0,
        border: '2px dashed white',
        padding: '30px 0px',
        height: 150,
        background: '#a5a3a0',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        width: '100%',
        overflow: 'auto',
        borderColor: '#FFFAFA',
        marginBottom: 10,
        textAlign: 'center',
    },
    inputFile1: {
        fontSize: 30,
        height: 150,
        width: 300,
        position: "absolute",
        opacity: 0
    },
    label: {
        fontSize: 15,
        fontFamily: 'sans-serif',
        fontStyle: 'normal',
    },
    db: {
        [theme.breakpoints.down('sm')]: {width: '100%'}
    }
}));

function getSteps() {
    return ['Inputs', 'Output', 'Api'];
}

function getStepContent(step, classes, handleUpload, state1, setState1, handleUploadvar, state, setState, handleUploadJar) {
    switch (step) {
        case 0:
            return <Grid
                className={classes.item}
                item
                md={12}
                sm={12}
                xs={12}
            >

                <Toolbar variant="dense">
                    <ListItemText primary='Import Inputs' secondary=' accepted files : csv , txt '/>
                </Toolbar>

                <input className={classes.inputFile1} type="file" name="file" accept=".xlsx , .csv , .txt"
                       onChange={handleUpload.bind(this)} style={{width: "100%"}}/>

                <Paper className={classes.dropzone} style={{color: "#cf1906"}}>
                    <p></p>
                    <CloudUploadIcon fontSize="large"/>
                </Paper>
                {state1.data.length === 0 ? null :

                    <div><Divider/>
                        <Hidden xsDown>


                            <Toolbar variant="dense" style={{minHeight: 20, maxWidth: 400}}>
                                <CheckCircleOutlineIcon style={{color: "#2ecc71"}}/>
                                <ListItem dense><ListItemText primary='Number of columns '/> {state1.data.length}
                                </ListItem>
                            </Toolbar>

                            <Divider style={{maxWidth: '100%'}}/>
                        </Hidden>
                    </div>
                }
                <Toolbar variant="dense">
                    <ListItem dense className={classes.db}>
                        <ListItemText primary=' '/>

                    </ListItem>
                </Toolbar>
                <MaterialTable
                    title="Inputs display  "
                    columns={state1.columns}
                    data={state1.data}
                    options={{
                        headerStyle: {
                            backgroundColor: '#cf1906',
                            color: '#FFF'
                        }
                    }}
                    editable={{
                        onRowAdd: (newData) =>
                            new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve();
                                    setState1((prevState) => {
                                        const data = [...prevState.data];
                                        data.push(newData);
                                        return {...prevState, data};
                                    });
                                }, 600);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve();
                                    if (oldData) {
                                        setState1((prevState) => {
                                            const data = [...prevState.data];
                                            data[data.indexOf(oldData)] = newData;
                                            return {...prevState, data};
                                        });
                                    }
                                }, 600);
                            }),
                        onRowDelete: (oldData) =>
                            new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve();
                                    setState1((prevState) => {
                                        const data = [...prevState.data];
                                        data.splice(data.indexOf(oldData), 1);
                                        return {...prevState, data};
                                    });
                                }, 600);
                            }),
                    }}
                />

            </Grid>;
        case 1:
            return <Grid
                className={classes.item}
                item
                md={12}
                sm={12}
                xs={12}
            >

                <Toolbar variant="dense">
                    <ListItemText primary='Import outputs' secondary=' accepted files : csv , txt '/>
                </Toolbar>
                <input className={classes.inputFile1} type="file" name="file" accept=".xlsx , .csv , .txt"
                       onChange={handleUploadvar.bind(this)} style={{width: "100%"}}/>

                <Paper className={classes.dropzone} style={{color: "#01579b"}}>
                    <p></p>
                    <CloudUploadIcon fontSize="large"/>
                </Paper>
                {state.data.length === 0 ? null :
                    <div><Divider/>
                        <Hidden xsDown>


                            <Toolbar variant="dense" style={{minHeight: 20, maxWidth: 400}}>
                                <CheckCircleOutlineIcon style={{color: "#2ecc71"}}/>
                                <ListItem dense><ListItemText primary='Number of columns '/> {state.data.length}
                                </ListItem>
                            </Toolbar>

                            <Divider style={{maxWidth: '100%'}}/>
                        </Hidden>
                    </div>
                }
                <Toolbar variant="dense">
                    <ListItem dense className={classes.db}>
                        <ListItemText primary=' '/>

                    </ListItem>
                </Toolbar>
                <MaterialTable
                    title="Output display "
                    columns={state.columns}
                    data={state.data}
                    options={{
                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF'
                        }
                    }}
                    editable={{
                        onRowAdd: (newData) =>
                            new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve();
                                    setState((prevState) => {
                                        const data = [...prevState.data];
                                        data.push(newData);
                                        return {...prevState, data};
                                    });
                                }, 600);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve();
                                    if (oldData) {
                                        setState((prevState) => {
                                            const data = [...prevState.data];
                                            data[data.indexOf(oldData)] = newData;
                                            return {...prevState, data};
                                        });
                                    }
                                }, 600);
                            }),
                        onRowDelete: (oldData) =>
                            new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve();
                                    setState((prevState) => {
                                        const data = [...prevState.data];
                                        data.splice(data.indexOf(oldData), 1);
                                        return {...prevState, data};
                                    });
                                }, 600);
                            }),
                    }}
                />


            </Grid>;
        case 2:
            return <div><Toolbar variant="dense">
                <ListItemText primary='Import a jar api' secondary=' accepted files : .jar  '/>
            </Toolbar>

                <DropzoneArea onChange={handleUploadJar} style={{background: "#c2c5c0"}}
                              dropzoneClass={classes.dropzoneClass}/></div>;
        default:
            return 'Unknown step';
    }
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Preprocessingdetails = props => {
    const {className, history, setValues, url, values, state1, setState1, state, setState, ...rest} = props;
    let path = url;
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        console.log(activeStep)
        if (activeStep == 2) {

            postfiles();


        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);


    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const useFormInput = initialValue => {
        const [value, setValue] = useState(initialValue);
        const handleInput = e => setValue(e.target.value);
        return {value, onChange: handleInput}
    }
    const [loading, setLoading] = useState(false);

    const [open, setOpen] = React.useState(false);
    const [input, setInput] = React.useState(false);

    const handleClick = () => setOpen(!open)
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);

    };


    const handleUpload = (e) => {
        let fileObj = e.target.files[0];
        let hed = []
        if (!fileObj.name.match(/.(csv || xlsx || txt)$/i))
            alert('file format is not correct , please select an csv or txt file')
        else if (fileObj.size > (1048576 * 500)) alert('Max file size is 500 Mb');
        else
            ExcelRenderer(fileObj, (err, resp) => {

                for (let i = 0; i < resp.rows[0].length; i++) {
                    hed.push({name: resp.rows[0][i], type: resp.rows[1][i]})
                }


                setState1({
                    ...state,
                    ["data"]: hed
                });


                setTableInfo({rowName: false, header: false});
            });
    }
    const handleUploadvar = (e) => {
        let fileObj = e.target.files[0];
        let hed = []
        if (!fileObj.name.match(/.(csv || xlsx || txt)$/i))
            alert('file format is not correct , please select an csv or txt file')
        else if (fileObj.size > (1048576 * 500)) alert('Max file size is 500 Mb');
        else
            ExcelRenderer(fileObj, (err, resp) => {

                for (let i = 0; i < resp.rows[0].length; i++) {
                    hed.push({name: resp.rows[0][i], type: resp.rows[1][i]})
                }

                setState({
                    ...state,
                    ["data"]: hed
                });

                setTableInfo({rowName: false, header: false});
            });
    }
    const handleUploadJar = (files) => {
        console.log(files)
        setFiles(files)

    };

    const getNumberOfEmptyCell = () => {
        let count = 0;
        for (let i = 0, len = state1.length; i < len; i++)
            for (let j = 0, l = state1[0].length; j < l; j++)
                count += (state1[i][j] === undefined) ? 1 : 0;
        return count;

    }

    let count = getNumberOfEmptyCell();
    const [TableInfo, setTableInfo] = useState({header: false, rowName: false})
    const handleChange = name => event => {
        setTableInfo({...TableInfo, [name]: event.target.checked})
    };

    const columns = ["Name", "type"];
    const postfiles = () => {
        const data = new FormData()
        if (files.length > 0) {
            setValues({
                ...values,
                ["valueofjar"]: files[0].name.split(".jar")[0]
            });
            for (var x = 0; x < files.length; x++) data.append('file', files[x])
            axios.post(`${url}/updateJar/`, data, {
                headers: {'Content-Type': 'application/json'},

            }).then(res => { // then print response status
                setOpen(true);

            })
        }
    }
    const [files, setFiles] = React.useState([]);

    const options = {
        filterType: 'checkbox',
    };

    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >

            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                            <Typography>{getStepContent(index, classes, handleUpload, state1, setState1, handleUploadvar, state, setState, handleUploadJar)}</Typography>
                            <div className={classes.actionsContainer}>
                                <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={classes.button}
                                    >
                                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Typography> </Typography>
                    <Button onClick={handleReset} className={classes.button}>
                        Reset
                    </Button>
                </Paper>
            )}

            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    jar uploaded with successes
                </Alert>
            </Snackbar>


        </Card>


    );
};

Preprocessingdetails.propTypes = {
    className: PropTypes.string
};

export default Preprocessingdetails;
