import React, {useState, useEffect} from 'react';
import {Switch, Redirect} from 'react-router-dom';

import {RouteWithLayout} from './Components';
import {Main as MainLayout, Minimal as MinimalLayout} from './layouts';
import {getToken, getUser, removeUserSession, getRole} from './Utils/common';

import {
    Plan as PlanView,
    Payments as PaymentsView,
    Acls as AclsView,
    Swagger as SwaggerView,
    Nodered as NoderedView,
    NotFound as NotFoundView,
    ConnectorsList as ConnectorsView,
    Topics as TopicsView,
    Dashboard as DashboardView,
    Settings as SettingsView,
    ApiList as Api,
    ApiDetails as ApiDetailsView,
    ApiUpdate as ApiUpdateView,
    Steps as StepsView,
    DbaseManager as DbaseManagerView,
    SignIn as SignTnView,
    UserList as UserListView,
    Account as AccountView,
    Flows as FlowsView,
    Library as LibraryView,
    SignUp as SignUpView, SignIn as SignInView
} from './views';
import axios from 'axios';

const path = process.env.REACT_APP_BASE_URL;

/**
const Routes = ({history}) => {

    useEffect(() => {
        const token = getToken();
        if (!token) return
        let config = {headers: {'Authorization': 'Bearer ' + getToken()}}
        axios.get(path + '/verifyToken', config).then(response => {
        }).catch(error => {
            removeUserSession();
            history.push("/signin")
        });
    }, []);

    const user = getUser();
    const isAuthenticated = Boolean(user);

    return (
        <Switch>
            {isAuthenticated?
                <Redirect exact from="/" to="/steps"/> :
                <Redirect exact from="/" to="/signin"/>
            }


            <RouteWithLayout component={SignTnView} exact layout={MinimalLayout} path="/signin" url={path} history={history}/>
            <RouteWithLayout component={SignUpView} exact layout={MinimalLayout} path="/signup" url={path} history={history}/>

            {
                isAuthenticated && user.admin &&
                    <RouteWithLayout component={UserListView} exact layout={MainLayout} path="/users" url={path} history={history}/>
            }

            {isAuthenticated &&
                <>
                    <RouteWithLayout component={DashboardView} exact layout={MainLayout} path="/dashboard" url={path} history={history} variable={variable}/>
                    <RouteWithLayout component={AclsView} exact layout={MainLayout} path="/things" url={path} history={history}/>
                    <RouteWithLayout component={NoderedView} exact layout={MainLayout} path="/nodered/:id" url={path} history={history}/>
                    <RouteWithLayout component={FlowsView} exact layout={MainLayout} path="/flows" url={path} history={history}/>
                    <RouteWithLayout component={DbaseManagerView} exact layout={MainLayout} path="/dbmanager" url={path} history={history}/>
                    <RouteWithLayout component={StepsView} exact layout={MainLayout} path="/steps" url={path} history={history}/>
                    <RouteWithLayout component={AccountView} exact layout={MainLayout} path="/account" url={path} history={history}/>
                    <RouteWithLayout component={PlanView} exact layout={MainLayout} path="/plan" url={path} history={history}/>
                    <RouteWithLayout component={PaymentsView} exact layout={MainLayout} path="/plan/payments" url={path} history={history}/>
                    <RouteWithLayout component={SettingsView} exact layout={MainLayout} path="/settings" url={path} history={history}/>
                    <RouteWithLayout component={TopicsView} exact layout={MainLayout} path="/topics" url={path} history={history}/>
                    <RouteWithLayout component={Api} exact layout={MainLayout} path="/apis" url={path} history={history}/>
                    <RouteWithLayout component={ApiDetailsView} exact layout={MainLayout} path="/apidetails" url={path} history={history}/>
                    <RouteWithLayout component={ApiUpdateView} exact layout={MainLayout} path="/apiupdate/:id" url={path} history={history}/>
                    <RouteWithLayout component={LibraryView} exact layout={MainLayout} path="/downloads" url={path} history={history}/>
                    <RouteWithLayout component={ConnectorsView} exact layout={MainLayout} path="/connectors" url={path} history={history} variable={variable}/>
                    <RouteWithLayout component={SwaggerView} exact layout={MainLayout} path="/swagger" url={path} history={history}/>
                </>
            }

            <RouteWithLayout component={NotFoundView} exact layout={MinimalLayout} path="/not-found" history={history}/>

            <Redirect to="/not-found"/>
        </Switch>
    );
};
**/


const Routes = () =>  [
    { path: '/signin', exact: true, component: SignTnView, layout: 'MinimalLayout' },
    { path: '/signup', exact: true, component: SignUpView, layout: 'MinimalLayout' },
    { path: '/', exact: true, component: DashboardView, layout: 'MainLayout', requiresAuth: true },
    { path: '/dashboard', exact: true, component: DashboardView, layout: 'MainLayout', requiresAuth: true },
    { path: '/things', exact: true, component: AclsView, layout: 'MainLayout', requiresAuth: true },
    { path: '/nodered/:id', exact: true, component: NoderedView, layout: 'MainLayout', requiresAuth: true },
    { path: '/flows', exact: true, component: FlowsView, layout: 'MainLayout', requiresAuth: true },
    { path: '/dbmanager', exact: true, component: DbaseManagerView, layout: 'MainLayout', requiresAuth: true },
    { path: '/steps', exact: true, component: StepsView, layout: 'MainLayout', requiresAuth: true },
    { path: '/account', exact: true, component: AccountView, layout: 'MainLayout', requiresAuth: true },
    { path: '/plan', exact: true, component: PlanView, layout: 'MainLayout', requiresAuth: true },
    { path: '/plan/payments', exact: true, component: PaymentsView, layout: 'MainLayout', requiresAuth: true },
    { path: '/settings', exact: true, component: SettingsView, layout: 'MainLayout', requiresAuth: true },
    { path: '/topics', exact: true, component: TopicsView, layout: 'MainLayout', requiresAuth: true },
    { path: '/apis', exact: true, component: Api, layout: 'MainLayout', requiresAuth: true },
    { path: '/downloads', exact: true, component: LibraryView, layout: 'MainLayout', requiresAuth: true },
    { path: '/connectors', exact: true, component: ConnectorsView, layout: 'MainLayout', requiresAuth: true },
    { path: '/swagger', exact: true, component: SwaggerView, layout: 'MainLayout', requiresAuth: true },
    { path: '/not-found', exact: true, component: NotFoundView, layout: 'MinimalLayout' },
    { path: '/users', exact: true, component: UserListView, layout: 'MainLayout', requiresAuth: true }
];

export default Routes;
