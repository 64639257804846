import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Bar} from 'react-chartjs-2';
import {makeStyles} from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Button, Grid, Typography, Avatar
} from '@material-ui/core';
import StorageIcon from '@material-ui/icons/Storage';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import {data, options} from './chart';
import CardActionArea from "@material-ui/core/CardActionArea";
import SaveIcon from "@material-ui/icons/Save";
import {getToken} from "../../../../Utils/common";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    content: {
        alignItems: 'center',
        display: 'flex'
    },
    title: {
        fontWeight: 700
    },
    avatar: {
        backgroundColor: theme.palette.success.main,
        height: 56,
        width: 56
    },
    icon: {
        height: 32,
        width: 32
    },
    difference: {
        marginTop: theme.spacing(0.5),
        display: 'flex',
        alignItems: 'center'
    },
    differenceIcon: {
        color: theme.palette.success.dark
    },
    icondown: {
        color: theme.palette.warning.dark,
        marginRight: theme.spacing(0.5)
    }, icondowntype: {

        color: theme.palette.warning.dark,

    },
    caption: {
        marginRight: theme.spacing(0.5)
    },
    differenceValue: {
        color: theme.palette.success.dark,
        marginRight: theme.spacing(0.5)
    }
}));

const LatestSales = props => {
    const {className, url, history, ...rest} = props;

    const classes = useStyles();
    const [values, setValues] = useState("")
    const [run, setRun] = useState(0)
    const [cpu, setcpu] = useState(0)

    const [inbyte, setinbyte] = useState(0)
    const [outbyte, setoutbyte] = useState(0)
    const [inmessage, setinmessage] = useState(0)
    var l = 0, m = 0, k = 0;

    const fetchDatabytepersecout = async () => {
        try {
            var d = new Date();
            var n = d.toISOString();
            let res = await fetch(`${url}/getMetrics/` + "mosout",
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            var vl = myjson.data.result;
            var variable = 0
            for (let i = 0; i < vl.length; i++) {
                variable = parseInt(vl[i].value[1]) + variable;
                console.log(vl[i])
            }

            var val = variable
            setoutbyte(val.toString())

        } catch (err) {
            setoutbyte("error")
        }
    }

    const fetchDatamessage = async () => {
        try {
            var d = new Date();
            var n = d.toISOString();
            let res = await fetch(`${url}/getMetrics/` + "messagemos",
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            var vl = myjson.data.result;
            var variable = 0
            for (let i = 0; i < vl.length; i++) {
                variable = parseInt(vl[i].value[1]) + variable;
                console.log(vl[i])
            }

            var val = variable
            setinmessage(val.toString())

        } catch (err) {
            setinmessage("error")
        }
    }

    const fetchDataCpu = async () => {
        try {
            var d = new Date();
            var n = d.toISOString();
            let res = await fetch(`${url}/getMetrics/` + "mossub",
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            var vl = myjson.data.result;
            var variable = 0

            var val = vl[0].value[1];
            setcpu(val.toString())

        } catch (err) {
            setcpu("error")
        }
    }

    var i = 0, j = 0;
    const fetchDatabytepersec = async () => {
        try {
            var d = new Date();
            var n = d.toISOString();
            let res = await fetch(`${url}/getMetrics/` + "mosin",
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            var vl = myjson.data.result;
            var variable = 0
            for (let i = 0; i < vl.length; i++) {
                variable = parseInt(vl[i].value[1]) + variable;
                console.log(vl[i])
            }

            var val = variable
            setinbyte(val.toString())

        } catch (err) {
            setinbyte("error")
        }
    }

    const fetchData = async () => {
        try {
            var d = new Date();
            var n = d.toISOString();
            let res = await fetch(`${url}/getMetrics/` + "totalclient",
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();

            var vl = myjson.data.result;
            var variable = 0
            for (let i = 0; i < vl.length; i++) {
                variable = parseInt(vl[i].value[1]) + variable;
                console.log(vl[i])
            }

            var val = variable;
            setValues(val.toString())

        } catch (err) {
            setValues("error")
        }
    }

    const goto = () => {
        window.open(process.env.REACT_APP_DNS_URL+"/grafana/d/Ie16aZLVz/mosquitto-broke?orgId=1&refresh=10s", "_blank");
    }

    useEffect(() => {
        fetchData()
    }, []);
    useEffect(() => {
        fetchDatabytepersec()
    }, []);
    useEffect(() => {
        fetchDatabytepersecout()
    }, []);
    useEffect(() => {
        fetchDatamessage()
    }, []);
    useEffect(() => {
        fetchDataCpu()
    }, []);

    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardActionArea onClick={goto} target="_blank"
                            href={process.env.REACT_APP_DNS_URL+"/grafana/d/Ie16aZLVz/mosquitto-broke?orgId=1&refresh=10s"}>
                <CardContent>
                    <Grid
                        container
                        justify="space-between"
                    >
                        <Grid item>
                            <Typography
                                className={classes.title}
                                color="textSecondary"
                                gutterBottom
                                variant="body2"
                            >
                                Client Total | Total subscriptions
                            </Typography>
                            <Typography variant="h3">{values} | {cpu}</Typography>
                        </Grid>
                        <Grid item>
                            <Avatar className={classes.avatar}>
                                <StorageIcon className={classes.icon}/>
                            </Avatar>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        justify="space-between"
                    >
                        <Grid xs={12}>
                            <div className={classes.difference}>


                                <Typography
                                    className={classes.caption}
                                    variant="caption"
                                >
                                    Bytes in per sec
                                </Typography>
                                <Typography
                                    className={classes.differenceValue}
                                    variant="body2"
                                >
                                    {inbyte} |
                                </Typography>
                                <Typography
                                    className={classes.caption}
                                    variant="caption"
                                >
                                    Bytes out per sec

                                </Typography>
                                <Typography
                                    className={classes.differenceValue}
                                    variant="body2"
                                >
                                    {outbyte} |
                                </Typography>
                                <Typography
                                    className={classes.caption}
                                    variant="caption"
                                >
                                    Tolal messages received
                                </Typography>
                                <Typography
                                    className={classes.differenceValue}
                                    variant="body2"
                                >
                                    {inmessage}
                                </Typography>
                                <Typography
                                    className={classes.caption}
                                    variant="caption"
                                >

                                </Typography>
                            </div>
                        </Grid>

                    </Grid>


                </CardContent>
            </CardActionArea>
        </Card>
    );
};

LatestSales.propTypes = {
    className: PropTypes.string
};

export default LatestSales;
