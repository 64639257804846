import React, {useState} from 'react';
import {Divider, Grid, TextField} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import {AntTab, AntTabs, TabPanel} from './CustomTabs'; // Import custom tabs component
import {updateThing, withErrorHandlingAndLoading} from "../../constants/functions";
import Loading from "../../Components/Loading/Loading";
import IconButton from "@material-ui/core/IconButton";
import {actionTypes} from "../../StateContext/types";
import {useStateValue} from "../../StateContext";
import {DetailsTab} from "./components/DetailsTab";
import {CredentialsTab} from "./components/CredentialsTab";
import {PermissionsTab} from "./components/PermissionsTab"; // Import custom list component

const ThingDetails = ({ selectData,thingName , handleThingNameChange, handleAlert, handleChange, value }) =>{
    const [loading,setLoading] = useState(false);
    const {thingsDispatch} = useStateValue();

    const handleUpdate = withErrorHandlingAndLoading(async () => {
        await updateThing(selectData.thing_id , {name : thingName});
        await thingsDispatch({
            type : actionTypes.UPDATE_THING ,
            thingId : selectData.thing_id,
            name : thingName
        })
        handleAlert(true,'Thing updated successfully','success');
    },setLoading,handleAlert )

    return(
        <div role="presentation">
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: 8 }}>
                        <Grid item>
                            <TextField
                                autoFocus
                                value={thingName}
                                margin="dense"
                                label="Thing Name"
                                type="text"
                                fullWidth
                                onChange={handleThingNameChange}
                            />
                        </Grid>
                        <Grid item>
                            <IconButton
                                aria-controls="customized-menu"
                                aria-haspopup="true"
                                color="primary"
                                onClick={handleUpdate}
                            >
                                <SaveIcon />
                            </IconButton>

                           { loading && <Loading color="secondary" />}

                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                        <AntTab label="Details" />
                        <AntTab label="Credentials" />
                        <AntTab label="Permissions" />
                    </AntTabs>
                    <TabPanel value={value} index={0}>
                        {/* Details Tab */}
                        <DetailsTab selectData={selectData} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {/* Credentials Tab */}
                        <CredentialsTab selectData={selectData} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        {/* Permissions Tab */}
                        <PermissionsTab selectData={selectData} handleAlert={handleAlert} />
                    </TabPanel>
                </Grid>
            </Grid>
        </div>
    )
};

export default ThingDetails;
