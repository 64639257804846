import axios from "axios";
import {base_url, errorHandler, getTokenFromLocalStorage} from "./functions";

const users_url = base_url + '/api/users';
const billing_url = base_url + '/billing';
axios.defaults.headers.common['Authorization'] = getTokenFromLocalStorage();

export const fetchUsers = async () => {
    try {
        const response = await axios.get(`${users_url}`);
        return response.data.users;
    } catch (error) {
        errorHandler(error);
    }
}

export const addUser = async (isAdmin, user) => {
    try {
        const endpoint = isAdmin ? `${users_url}/admin` : users_url
        const response = await axios.post(endpoint, user);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

export const deleteUser = async (id) => {
    try {
        const response = await axios.delete(`${users_url}/${id}`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

export const updateUser = async (isAdmin, values) => {
    try {
        const response = await axios.put(`${users_url}/admin/${values.id}`, values);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

export const addBillingAddress = async (address) => {
    try {
        const response = await axios.post(`${billing_url}/address`, address);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

export const listBillingAddress = async () => {
    try {
        const response = await axios.get(`${billing_url}/address`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

