import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Grid} from '@material-ui/core';

import {Notifications, Password} from './components';
import CssBaseline from "@material-ui/core/CssBaseline";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}));

const Settings = ({url}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <Grid
                container
                spacing={4}
            >
                <Grid
                    item
                    md={12}
                    xs={12}
                >

                    <Password url={url}/>
                </Grid>
            </Grid>
        </div>
    );
};

export default Settings;
